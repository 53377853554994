/**
 * Created by Denis Kuzin on 20 january 2025
 */

CustomerRecipe.createRecipeTemplate = function (customer, recipeIndex) {
    if (!Map2d.currentMap.checkUnitBound(customer.unit)) {
        return undefined;
    }

    if (customer.needsFirstRecipe || recipeIndex === undefined) {
        var customerId = cleverapps.config.name === "hearts" ? CustomerRecipe.CUSTOMER_CODE_TO_ID[customer.unit.code] : customer.unit.x;
        var order = Merge.currentMerge.orderManager.getCurrentOrder();

        if (!order) {
            return undefined;
        }

        var location = cleverapps.meta.getSelectedLocation();
        if (typeof GardenLocation !== "undefined"
            && location instanceof GardenLocation
            && location.stars > CustomerRecipe.RECIPE_BLOCK_CONDITIONS.MIN_STARS
            && location.stage < CustomerRecipe.RECIPE_BLOCK_CONDITIONS.MAX_STAGE) {
            return undefined;
        }

        if (order.targetCustomerIds === undefined || order.targetCustomerIds.includes(customerId)) {
            return order;
        }

        return undefined;
    }

    return Merge.currentMerge.orderManager.getOrderByIndex(recipeIndex);
};

CustomerRecipe.CUSTOMER_CODE_TO_ID = {
    z: 0,
    y: 1,
    x: 2
};

CustomerRecipe.RECIPE_BLOCK_CONDITIONS = {
    MIN_STARS: 200,
    MAX_STAGE: 3
};