/**
 * Created by anatoly on 23.03.2025
 */

var ValuableHintLogic = {
    type: "valuable",

    findMove: function () {
        var units = Map2d.currentMap.listAvailableUnits();

        var solutionUnit;
        for (var i = 0; i < units.length; i++) {
            var unit = units[i];

            if (unit.getType() === "valuables") {
                solutionUnit = unit;
                break;
            }
        }

        if (solutionUnit && solutionUnit.onGetView()) {
            return {
                type: ValuableHintLogic.type,
                options: {
                    target: solutionUnit.onGetView()
                }
            };
        }
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(ValuableHintLogic);