/**
 * Created by anatoly on 12.03.2025
 */

var UnitInfo = function () {
    cleverapps.EventEmitter.call(this);

    this.unit = undefined;

    this.lastSelection = undefined;
    this.currentSelection = undefined;
};

UnitInfo.prototype = Object.create(cleverapps.EventEmitter.prototype);
UnitInfo.prototype.constructor = UnitInfo;

UnitInfo.prototype.selectUnit = function (unit) {
    this.unit = unit;

    this.lastSelection = this.currentSelection;
    this.currentSelection = { x: unit.x, y: unit.y };

    this.trigger("onSelectUnit", unit);
};

UnitInfo.prototype.hideSelect = function () {
    this.currentSelection = undefined;
    this.unit = undefined;

    this.trigger("onHideSelect");
};

UnitInfo.prototype.canSellUnit = function () {
    return this.unit
        && this.unit.getType() !== "mineable"
        && this.unit.getType() !== "valuables"
        && !this.unit.isGrounded();
};

UnitInfo.prototype.showSellUnitWindow = function () {
    if (this.canSellUnit()) {
        cleverapps.focusManager.display({
            focus: "SellUnitWindow",
            action: function (f) {
                new SellUnitWindow(this.unit);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });
    }
};