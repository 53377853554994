/**
 * Created by Vladislav on 31.03.2025.
 */

var MetaSwitchButton = cleverapps.UI.Button.extend({
    ctor: function () {
        var styles = cleverapps.styles.MetaSwitchButton;

        this.avoidNode = "MetaSwitchButton";

        this._super({
            width: styles.width,
            height: styles.height,
            content: new cc.Sprite(bundles.buttons.frames.meta_button_icon),
            type: cleverapps.styles.UI.Button.Images.blue_round_button,
            onClicked: function () {
                var action = function (f) {
                    cleverapps.focusManager.hideControlsWhileFocused(["metaSwitchButton", "MenuBarLivesItem"]);
                    cleverapps.focusManager.showControlsWhileFocused(["gameSwitchComponent", "roomRepairView"]);

                    cleverapps.meta.getSelectedLocation().changeMode(GardenLocation.MODE_META, f);
                };

                if (cleverapps.tutorial.isActive()) {
                    action(function () {
                        cleverapps.tutorial.trigger("metaSwitchButton");
                    });

                    return;
                }

                cleverapps.focusManager.display({
                    focus: "MetaSwitchButton",
                    keepControls: true,
                    action: action
                });
            }
        });

        this.attentionIcon = new cc.Sprite(bundles.merge.frames.attention_meta);
        this.addChild(this.attentionIcon);
        this.attentionIcon.setPositionRound(styles.attention);

        this.updateAttention();

        cleverapps.meta.getSelectedLocation().on("changeStars", this.updateAttention.bind(this));
    },

    updateAttention: function () {
        var location = cleverapps.meta.getSelectedLocation();

        this.attentionIcon.setVisible(location.stars >= location.getCurrentStagePrice());
    }
});

cleverapps.styles.MetaSwitchButton = {
    width: 144,
    height: 146,

    attention: {
        x: { align: "right" },
        y: { align: "top" }
    }
};