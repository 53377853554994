/**
 * Created by Denis Kuzin on 16 january 2025
 */

Map2d.prototype.isVisibleCell = function () {
    return true;
};

Map2d.prototype.isScreenCellPosition = function () {
    return true;
};

Map2d.prototype.selectUnit = function (unit) {
    this.unitInfo.selectUnit(unit);
};

Map2d.prototype.hideSelect = function () {
    this.unitInfo.hideSelect();
};
