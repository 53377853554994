/**
 * Created by razial on 25.03.2025.
 */

var GardenLocation = function (locationId) {
    BaseLocation.call(this, "garden", locationId);

    this.slot = GardenLocation.CalcSlot(locationId);

    this.families = Families.listExpeditionCodes("main");

    this.meta = bundles["location_" + locationId].meta;

    this.adapter = new GardenLocationAdapter(this.slot);

    this.stars = this.adapter.load("stars") || 0;
    this.stage = this.adapter.load("stage") || 0;

    this.totalStages = bundles["room_" + locationId].meta.stages;

    this.mode = GardenLocation.MODE_GAME;
};

GardenLocation.prototype = Object.create(BaseLocation.prototype);
GardenLocation.prototype.constructor = GardenLocation;

GardenLocation.MODE_GAME = 0;
GardenLocation.MODE_META = 1;

GardenLocation.CHANGE_ANIMATION_DURATION = 150;
GardenLocation.CHANGE_STAGE_DURATION = 1000;

GardenLocation.prototype.changeMode = function (mode, f) {
    this.mode = mode;

    this.trigger("changeMode");

    cleverapps.timeouts.setTimeout(f, GardenLocation.CHANGE_ANIMATION_DURATION);
};

GardenLocation.prototype.isGameMode = function () {
    return this.mode === GardenLocation.MODE_GAME;
};

GardenLocation.prototype.buyNextStage = function (f) {
    var price = this.getCurrentStagePrice();

    if (this.takeStars(price)) {
        this.nextStage(f);
    } else {
        f();
    }
};

GardenLocation.prototype.nextStage = function (f) {
    this.stage++;

    this.adapter.save("stage", this.stage);

    this.trigger("nextStage", this.stage);

    cleverapps.timeouts.setTimeout(f, GardenLocation.CHANGE_STAGE_DURATION);
};

GardenLocation.prototype.reset = function () {
    this.stage = 0;

    this.adapter.save("stage", this.stage);

    this.trigger("reset", this.stage);
};

GardenLocation.prototype.getCurrentStagePrice = function () {
    return 40;
};

GardenLocation.prototype.isCompleted = function () {
    return this.stage >= this.totalStages;
};

GardenLocation.prototype.addStars = function (stars, silent) {
    this.stars += stars;
    this.adapter.save("stars", this.stars);

    if (!silent) {
        this.onChangeStars(stars);
    }
};

GardenLocation.prototype.takeStars = function (stars) {
    if (stars > this.stars) {
        cleverapps.notification.create("NoStars");
        return false;
    }

    this.stars -= stars;

    this.adapter.save("stars", this.stars);

    this.onChangeStars(stars);

    return true;
};

GardenLocation.prototype.onChangeStars = function () {
    this.trigger("changeStars", this.stars);
};

GardenLocation.prototype.withWands = function () {

};

GardenLocation.prototype.getPendingProgress = function () {
    return 0;
};

GardenLocation.prototype.getProgress = function () {
    return this.stage / this.totalStages * 100;
};

GardenLocation.prototype.moveNextLocation = function () {
    cleverapps.focusManager.display({
        focus: "gardenMoveNextLocation",
        actions: [
            function (f) {
                cleverapps.meta.moveNextLocation();
                f();
            },
            function (f) {
                if (cleverapps.meta.isCompleted()) {
                    new AllDoneWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                    return;
                }

                new TravelMapWindow({ animateNext: true });
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                cleverapps.meta.gotoMainLocation(f);
            }
        ]
    });
};

GardenLocation.GetMainScene = function () {
    return Merge2Scene;
};

GardenLocation.CalcSlot = function (locationId) {
    var slot = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2][locationId % 3];

    return slot !== undefined ? slot : Meta.EXPEDITION_SLOT3;
};
