/**
 * Created by Vladislav on 02.04.2025.
 */

var RoomRepairView = cleverapps.Spine.extend({
    ctor: function () {
        this._super(bundles.merge.jsons.repair_bg_json);
        this.setAnimation(0, "animation", true);

        var location = cleverapps.meta.getSelectedLocation();

        var iconSlot = this.getSlot("slot_item");
        var icon = new cc.Sprite(bundles["room_" + location.locationId].frames["icon_item_" + location.stage]);
        iconSlot.addChild(icon);
        icon.setPositionRound(iconSlot.width / 2, iconSlot.height / 2);

        var textSlot = this.getSlot("slot_text_with_icon");
        var textWithIcon = new TextWithIcon(location.getCurrentStagePrice() + "%%", {
            font: cleverapps.styles.FONTS.REPAIR_TEXT,
            icons: {
                "%%": bundles.reward_icons.frames.order_completion_star_png
            }
        });
        textSlot.addChild(textWithIcon);
        textWithIcon.setPositionRound(textSlot.width / 2, textSlot.height / 2);

        this.setLocalZOrder(10);

        this.setCascadeOpacityEnabledRecursively(true);

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, function () {
            var action = function (f) {
                location.buyNextStage(f);
            };

            if (cleverapps.tutorial.isActive()) {
                action(function () {
                    cleverapps.tutorial.trigger("roomRepair");
                });

                return;
            }

            cleverapps.focusManager.display({
                focus: "RepairClick",
                control: "roomRepairView",
                action: action
            });
        });

        if (cleverapps.config.debugMode) {
            location.on("reset", function () {
                icon.setSpriteFrame(bundles["room_" + location.locationId].frames["icon_item_" + location.stage]);
                textWithIcon.setString(location.getCurrentStagePrice() + "%%");
            }, this);
        }
    },

    showAnimation: function (silent) {
        this.visible = true;
        if (silent) {
            this.setScale(1);
            return;
        }
        this.runAction(new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut()));
    },

    hideAnimation: function (silent) {
        if (silent) {
            this.visible = false;
            this.setScale(0);
            return;
        }
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.2, 0).easing(cc.easeBackIn()),
            new cc.Hide()
        ));
    }
});