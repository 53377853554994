/**
 * Created by Vladislav on 01.04.2025.
 */

var GameSwitchComponent = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        var button = this.gameSwitchButton = new GameSwitchButton(this.switchToGameAction.bind(this));
        this.addChild(button);

        var energyInfo = this.createEnergyInfo();
        this.addChild(energyInfo);

        cleverapps.UI.wrap(this);

        this.avoidNode = "GameSwitchComponent";
    },

    switchToGameAction: function (f) {
        cleverapps.focusManager.hideControlsWhileFocused(["gameSwitchComponent", "MenuBarGardenProgressItem", "roomRepairView"]);
        cleverapps.focusManager.showControlsWhileFocused("metaSwitchButton");

        cleverapps.meta.getSelectedLocation().changeMode(GardenLocation.MODE_GAME, f);
    },

    createEnergyInfo: function () {
        var styles = cleverapps.styles.GameSwitchComponent;

        var energyInfo = new cc.Sprite(bundles.merge.frames.energy_info_bg);
        energyInfo.setPositionRound(styles.energy);

        var icon = new cc.Sprite(bundles.reward_icons.frames.energy_small_png);
        energyInfo.addChild(icon);
        icon.setPositionRound(styles.energy.icon);

        var amount = cleverapps.UI.generateImageText(cleverapps.lives.amount, cleverapps.styles.FONTS.SMALL_TEXT);
        energyInfo.addChild(amount);
        amount.setPositionRound(styles.energy.amount);

        cleverapps.lives.onChangeAmountListeners.gameSwitchComponent = function () {
            amount.setString(cleverapps.lives.amount);
        };

        cleverapps.UI.onClick(energyInfo, function () {
            cleverapps.focusManager.display({
                focus: "EnergyInfoClick",
                control: ["MenuBarLivesItem", "MenuBarGoldItem"],
                actions: [
                    this.switchToGameAction.bind(this),
                    function (f) {
                        new LivesShopWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                ]
            });
        }.bind(this));

        addCleaner(this, function () {
            delete cleverapps.lives.onChangeAmountListeners.gameSwitchComponent;
        });

        return energyInfo;
    }
});

cleverapps.styles.GameSwitchComponent = {
    energy: {
        x: { align: "center", dx: 30 },
        y: { align: "center", dy: 140 },

        icon: {
            x: { align: "center" },
            y: { align: "center", dy: 33 }
        },

        amount: {
            x: { align: "center" },
            y: { align: "center", dy: -18 }
        }
    }
};