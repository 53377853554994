/**
 * Created by Denis Kuzin on 27 march 2025
 */

var OrderManager = function (adapter) {
    this.adapter = adapter;

    this.index = 0;
    this.orders = [];

    this.init();

    this.load();
};

OrderManager.prototype.init = function () {
    this.orders = bundles.merge.jsons.orders_json.resolve().getJson();
};

OrderManager.prototype.load = function () {
    this.index = this.adapter.load("orderIndex") || 0;
};

OrderManager.prototype.save = function () {
    this.adapter.save("orderIndex", this.index);
};

OrderManager.prototype.getCurrentOrder = function () {
    return this.getOrderByIndex(this.index);
};

OrderManager.prototype.getOrderByIndex = function (index) {
    var order = this.orders[index];
    if (order) {
        order.index = index;

        if (index >= OrderManager.CUSTOMER_ORDER_STARTS.customer3StartOrderId) {
            order.targetCustomerIds = [0, 1, 2];
        } else if (index >= OrderManager.CUSTOMER_ORDER_STARTS.customer2StartOrderId) {
            order.targetCustomerIds = [0, 1];
        } else if (index >= OrderManager.CUSTOMER_ORDER_STARTS.customer1StartOrderId) {
            order.targetCustomerIds = [0];
        }

        return order;
    }

    return undefined;
};

OrderManager.prototype.inc = function () {
    this.index++;
};

OrderManager.CUSTOMER_ORDER_STARTS = {
    customer1StartOrderId: 0,
    customer2StartOrderId: 9,
    customer3StartOrderId: 28
};