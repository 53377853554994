/**
 * Created by mac on 2/28/23
 */

var Merge2Scene = MainScene.extend({
    destructor: function () {
        if (Merge.currentMerge) {
            Merge.currentMerge.stop();
            Merge.currentMerge = undefined;
        }

        this._super();
    },

    onSceneLoaded: function () {
        cleverapps.environment.setScene(cleverapps.Environment.SCENE_MAIN);

        this.game = new Merge();

        this._super({
            scene: cleverapps.Environment.SCENE_MAIN,
            episodeNo: cleverapps.meta.selectedLocationId(),
            levelNo: 0
        });

        new MergeHintView();
        new MineHintView();
        new OrderHintView();
        new ValuableHintView();
        new RepairHintView();

        var roomView = this.roomView = new RoomView();
        this.background.addChild(roomView);
        roomView.setPositionRound(this.background.width / 2, this.background.height / 2);

        var styles = cleverapps.styles.Merge2Scene;

        var ordersGroupIconView = this.ordersGroupIconView = new ScrollableIconTableView({
            allowInfoView: function () {},
            denyInfoView: function () {}
            // hideInfo: function () {}
        });
        this.addChild(ordersGroupIconView);
        ordersGroupIconView.setPositionRound(styles.ordersGroupIcon.position);
        this.ordersGroupIconViewControl = new HidingNode(ordersGroupIconView);
        this.ordersGroupIconViewControl.setScale(styles.ordersGroupIcon.scale[cleverapps.resolution.mode]);

        var unitInfoView = new UnitInfoView();
        this.addChild(unitInfoView, 1);
        this.unitInfoView = new HidingNode(unitInfoView);
        cleverapps.focusManager.registerControl("unitInfoView", this.unitInfoView);

        this.map = new Map2dFlatNode();
        this.addChild(this.map);
        this.mapControl = new HidingNode(this.map, cleverapps.UI.VERTICAL);

        var orangery = Map2d.currentMap.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }

        var metaSwitchButton = new MetaSwitchButton();
        this.addChild(metaSwitchButton);
        metaSwitchButton.setPositionRound(styles.metaSwitchButton);

        this.metaSwitchButton = new HidingNode(metaSwitchButton, cleverapps.UI.HORIZONTAL);

        cleverapps.focusManager.registerControl("metaSwitchButton", this.metaSwitchButton);

        var gameSwitchComponent = new GameSwitchComponent();
        this.addChild(gameSwitchComponent);
        gameSwitchComponent.setPositionRound(styles.gameSwitchComponent);

        this.gameSwitchComponent = new HidingNode(gameSwitchComponent, cleverapps.UI.HORIZONTAL);
        this.gameSwitchComponent.gameSwitchButton = gameSwitchComponent.gameSwitchButton;
        
        cleverapps.focusManager.registerControl("gameSwitchComponent", this.gameSwitchComponent);

        cleverapps.meta.getSelectedLocation().on("changeMode", this.onChangeMode.bind(this));

        this.onChangeMode(true);

        this.setupChildren();

        if (cleverapps.config.debugMode) {
            var debugLocationId = cleverapps.UI.generateImageText(cleverapps.meta.selectedLocationId(), cleverapps.styles.FONTS.WHITE_TEXT);
            this.addChild(debugLocationId);
            debugLocationId.setPositionRound({
                x: { align: "right", dx: -20 },
                y: { align: "top", dy: -160 }
            });
        }
    },

    getRoomView: function () {
        return this.roomView;
    },

    getOrdersGroupIconView: function () {
        return this.ordersGroupIconView;
    },

    getMetaSwitchButton: function () {
        return this.metaSwitchButton;
    },

    onChangeMode: function (silent) {
        var mode = cleverapps.meta.getSelectedLocation().mode;

        if (mode === GardenLocation.MODE_META) {
            cleverapps.focusManager.setHiddenByDefault("gameSwitchComponent", []);
            cleverapps.focusManager.setHiddenByDefault("metaSwitchButton", [cleverapps.Environment.SCENE_MAIN]);
            cleverapps.focusManager.setHiddenByDefault("unitInfoView", [cleverapps.Environment.SCENE_MAIN]);
            cleverapps.focusManager.setHiddenByDefault("MenuBarLivesItem", [cleverapps.Environment.SCENE_MAIN]);
            cleverapps.focusManager.setHiddenByDefault("MenuBarGardenProgressItem", []);
            cleverapps.focusManager.setHiddenByDefault("roomRepairView", []);
            this.ordersGroupIconViewControl.hide(silent);
            this.unitInfoView.hide(silent);
            this.mapControl.hide(silent);
        } else {
            cleverapps.focusManager.setHiddenByDefault("metaSwitchButton", []);
            cleverapps.focusManager.setHiddenByDefault("gameSwitchComponent", [cleverapps.Environment.SCENE_MAIN]);
            cleverapps.focusManager.setHiddenByDefault("unitInfoView", []);
            cleverapps.focusManager.setHiddenByDefault("MenuBarLivesItem", []);
            cleverapps.focusManager.setHiddenByDefault("MenuBarGardenProgressItem", [cleverapps.Environment.SCENE_MAIN]);
            cleverapps.focusManager.setHiddenByDefault("roomRepairView", [cleverapps.Environment.SCENE_MAIN]);
            this.ordersGroupIconViewControl.show(silent);
            this.unitInfoView.show(silent);
            this.mapControl.show(silent);
        }
    },

    getOverlappingNodes: function () {
        var items = [this.upMenuContainer, this.ordersGroupIconViewControl, this.unitInfoView];
        return items.filter(Boolean);
    },

    scaleGameField: function () {
        var styles = cleverapps.styles.Merge2Scene;

        var centerPoint = this.mapControl.calculatePositionRound(styles.lovesPosition[cleverapps.resolution.mode]);

        cleverapps.UI.inflateToBoundaries(this.mapControl, this.getOverlappingNodes(), {
            maxScale: 1,
            padding: cleverapps.styles.Merge2Scene.padding[cleverapps.resolution.mode],
            lovesPosition: this.convertToWorldSpace(centerPoint)
        });

        var scene = cleverapps.scenes.getRunningScene();

        var x = this.unitInfoView.x;
        var y = this.unitInfoView.y;

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            y = (this.mapControl.y - this.mapControl.height / 2 * this.mapControl.scale + styles.unitInfo.paddingBottom) / 2;
        } else {
            x = (scene.width + (this.mapControl.x + this.mapControl.width / 2 * this.mapControl.scale)) / 2;
        }

        this.unitInfoView.setPositionRound(x, y);

        x = this.ordersGroupIconViewControl.x;
        y = this.ordersGroupIconViewControl.y;

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            y = (scene.height - styles.ordersGroupIcon.paddingTop + (this.mapControl.y + this.mapControl.height / 2 * this.mapControl.scale)) / 2;
        } else {
            x = (this.mapControl.x - this.mapControl.width / 2 * this.mapControl.scale) / 2;
        }

        this.ordersGroupIconViewControl.setPositionRound(x, y);
    },

    playIntro: function (f, silent) {
        var actions = this.game.listIntroActions();

        if (silent) {
            actions = actions.map(function (action) {
                return function (f) {
                    action(f, silent);
                };
            });
        }

        this.game.introPlaying = true;

        cleverapps.focusManager.compound(function () {
            this.game.introPlaying = false;
            f();
        }.bind(this), actions);
    },

    getAnimationsLayer: function () {
        return this.map.mapView.animations;
    },

    getBackgroundStyles: function () {
        var styling = cleverapps.styles.GameScene.STYLING.regular[0];
        styling = cleverapps.clone(styling);

        var location = cleverapps.meta.getSelectedLocation();

        styling.bundle = "room_" + location.locationId;
        styling.backgroundId = "background_png";

        return styling;
    },

    listBundles: function () {
        var location = cleverapps.meta.getSelectedLocation();

        var result = NoneMainGameScene.prototype.listBundles.apply(this, arguments);
        result.push(Map2d.getTilesTexture(location.meta.tiles));
        result.push(Map2d.getUnitsTexture(location.meta.units));
        result.push("quests_" + location.meta.units);
        result.push("unitstories_" + location.locationId);
        result.push("room_" + location.locationId);

        return result;
    },

    getAudioStyles: function () {
        return {
            res: cleverapps.skins.getSlot("gameAudio") || this.styling.music,
            fadeIn: cleverapps.styles.GameScene.sound.fadeIn,
            delay: cleverapps.config.wysiwygMode ? 0 : cleverapps.styles.GameScene.sound.delay
        };
    },

    setupChildren: function () {
        this._super();
        var styles = cleverapps.styles.Merge2Scene;

        this.ordersGroupIconViewControl.setScale(styles.ordersGroupIcon.scale[cleverapps.resolution.mode]);
        this.ordersGroupIconViewControl.setPositionRound(styles.ordersGroupIcon.position);

        this.unitInfoView.setPositionRound(styles.unitInfo.position);
    },

    animateZoom: function (zoom, duration, f) {
        f();
    }
});

GameScene = Merge2Scene;

cleverapps.styles.Merge2Scene = {
    padding: [
        {
            top: 25,
            bottom: 15,
            left: 0,
            right: 0
        },
        {
            top: 40,
            bottom: 20,
            left: 20,
            right: 20
        },
        {
            top: 65,
            bottom: 25,
            left: 20,
            right: 20
        }
    ],

    lovesPosition: [
        {
            x: { align: "center" },
            y: { align: "center" }
        },
        {
            x: { align: "center", dx: 40 },
            y: { align: "center", dy: -50 }
        },
        {
            x: { align: "center", dx: 50 },
            y: { align: "center", dy: -50 }
        }
    ],

    ordersGroupIcon: {
        scale: [0.9, 0.7, 0.9],
        paddingTop: 100,
        position: [
            {
                x: { align: "center" },
                y: { align: "top", dy: -100 }
            },
            {
                x: { align: "left" },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "left" },
                y: { align: "center", dy: -100 }
            }
        ]
    },

    unitInfo: {
        paddingBottom: 150,
        position: [
            {
                x: { align: "center" },
                y: { align: "bottom", dy: 150 }
            },
            {
                x: { align: "right" },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "right" },
                y: { align: "center", dy: -100 }
            }
        ]
    },

    metaSwitchButton: {
        x: { align: "left" },
        y: { align: "bottom" }
    },

    gameSwitchComponent: {
        x: { align: "right" },
        y: { align: "bottom" }
    }
};
