/**
 * Created by anatoly on 31.01.2025
 */

var UnitInfoView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.unitInfo = Map2d.currentMap.unitInfo;
        this.state = this.calcState();

        this.setAnchorPoint(0.5, 0.5);
        this.setBackgroundSpine();

        this.addButtons();

        this.updateUnitInfo();

        this.showUnitText();

        this.updateSize();

        this.setupChildren();

        this.unitInfo.on("onSelectUnit", this.updateUnitInfo.bind(this));
        this.unitInfo.on("onHideSelect", this.updateUnitInfo.bind(this));
    },

    calcState: function () {
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            return UnitInfoView.STATES.VERTICAL;
        }

        return UnitInfoView.STATES.HORIZONTAL;
    },

    setBackgroundSpine: function () {
        if (this.backgroundSpine) {
            this.backgroundSpine.removeFromParent();
        }

        var bundle = bundles.merge.jsons.unit_info_spine;

        if (this.state === UnitInfoView.STATES.VERTICAL) {
            bundle = bundles.merge.jsons.unit_info_ver_spine;
        }

        this.backgroundSpine = new cleverapps.Spine(bundle);
        this.backgroundSpine.setAnimation(0, "animation", true);

        this.setContentSize(this.backgroundSpine.width, this.backgroundSpine.height);

        this.backgroundSpine.setPositionRound(this.width / 2, this.height / 2);

        this.addChild(this.backgroundSpine, -1);
    },

    updateUnitInfo: function (unit) {
        if (this.unitView) {
            this.unitView.removeFromParent();
        }

        this.deleteButton.setVisible(this.unitInfo.canSellUnit());
        this.helpButton.setVisible(Boolean(unit));

        this.showUnitText(unit);

        var unitView = unit ? UnitView.getUnitImage(unit) : new cc.Sprite(bundles.merge.frames.quest_icon);
        this.unitView = unitView;

        this.addChild(unitView);

        this.updateUnitView();
    },

    updateUnitView: function () {
        if (this.unitView) {
            var unitIconSlot = this.backgroundSpine.getSlot("slot_unit_icon");

            cleverapps.UI.fitToBox(this.unitView, {
                width: unitIconSlot.width,
                height: unitIconSlot.height
            });
            this.unitView.setPositionRound(unitIconSlot.getPosition());
        }
    },

    showUnitText: function (unit) {
        if (this.unitText) {
            this.unitText.removeFromParent();
        }

        var text = unit ? cleverapps.unitsLibrary.getUnitName(unit) : "UnitInfo.defaultText";
        text = this.unitText = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.UNIT_INFO_TEXT);

        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        this.addChild(text);
        this.updateText();
    },

    updateText: function () {
        var textSlot = this.backgroundSpine.getSlot("slot_info_text");

        this.unitText.setFont(cleverapps.styles.FONTS.UNIT_INFO_TEXT);

        this.unitText.setDimensions(textSlot.width, 0);
        this.unitText.fitTo(undefined, textSlot.height);

        this.unitText.setPositionRound(textSlot.getPosition());
    },

    addButtons: function () {
        var type = {
            button_png: bundles.merge.frames.unitinfo_button_bg,
            button_on_png: bundles.merge.frames.unitinfo_button_bg_on,
            button_off_png: bundles.merge.frames.unitinfo_button_bg_off
        };

        var helpButtonSlot = this.backgroundSpine.getSlot("slot_info_button");
        var deleteButtonSlot = this.backgroundSpine.getSlot("slot_sell_button");

        var helpButton = this.helpButton = new cleverapps.UI.Button({
            type: type,
            text: "?", // tmp
            onClicked: function () {
                cleverapps.focusManager.display({
                    focus: "HelpButtonClicked",
                    action: function (f) {
                        new UnitsLibraryWindow(this.unitInfo.unit);
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }.bind(this)
                });
            }.bind(this),
            width: helpButtonSlot.width,
            height: helpButtonSlot.height
        });

        this.addChild(helpButton);

        var deleteButton = this.deleteButton = new cleverapps.UI.Button({
            type: type,
            text: "%%",
            icons: {
                "%%": bundles.buttons_main.frames.delete_icon
            },
            onClicked: function () {
                this.unitInfo.showSellUnitWindow();
            }.bind(this),
            width: deleteButtonSlot.width,
            height: deleteButtonSlot.height
        });

        this.helpButton.setPosition(helpButtonSlot.getPosition());
        this.deleteButton.setPosition(deleteButtonSlot.getPosition());

        this.addChild(deleteButton);
    },

    updateButtons: function () {
        if (this.helpButton) {
            this.helpButton.removeFromParent();
        }

        if (this.deleteButton) {
            this.deleteButton.removeFromParent();
        }

        this.addButtons();
    },

    updateSize: function () {
        var state = this.calcState();
        if (this.state === state) {
            return;
        }

        this.state = state;

        this.setBackgroundSpine();

        this.setContentSize(this.backgroundSpine.width, this.backgroundSpine.height);

        this.updateText();
        this.updateButtons();

        this.updateUnitView();
    },

    setupChildren: function () {
        this.setScale(cleverapps.styles.UnitInfoView.scale[cleverapps.resolution.mode]);
    }
});

UnitInfoView.STATES = {
    VERTICAL: 0,
    HORIZONTAL: 1
};

cleverapps.overrideStyles(cleverapps.styles.FONTS, {
    UNIT_INFO_TEXT: {
        name: "default",
        color: new cc.Color(155, 88, 67, 255),
        size: 40
    }
});

cleverapps.styles.UnitInfoView = {
    scale: [0.9, 0.7, 0.9]
};