/**
 * Created by Vladislav on 28.03.2025.
 */

var Merge2CupTutorial = function () {
    cleverapps.eventBus.on("unitOpened", Merge2CupTutorial.onUnitOpened, Merge.currentMerge);

    return new TutorialScenario("merge2_cup_tutorial", {
        listSteps: function () {
            return [Merge2CupTutorial.createFirstStep()];
        },
        onStart: function () {
            cleverapps.focusManager.showControlsWhileFocused("map2d");
        },
        onFinish: function () {
            cleverapps.focusManager.hideControlsWhileFocused("map2d");
        },
        isAvailable: function () {
            if (!Merge2CupTutorial.available) {
                return false;
            }

            return Map2d.currentMap.countEmptySlots() >= 2;
        }
    });
};

Merge2CupTutorial.tutorialUnit = {
    code: "A",
    stage: 0
};

Merge2CupTutorial.onUnitOpened = function (unit) {
    if (Unit.Equals(Merge2CupTutorial.tutorialUnit, unit)) {
        Merge2CupTutorial.available = true;
    }
};

Merge2CupTutorial.createFirstStep = function () {
    var options = {
        onStart: function () {
            var unit = Map2d.currentMap.listAvailableUnits(Merge2CupTutorial.tutorialUnit).filter(function (unit) {
                return !unit.isGrounded();
            })[0];

            this.units = [unit];

            var unitView = unit.onGetView();

            this.force = cleverapps.forces.create(Map2d.currentMap.getMapView(), {
                text: "Merge2.CupTutorial1",
                finger: { tap: unitView }
            });

            cleverapps.eventBus.on("mapEvent", function (type, options) {
                if (type === Map2d.CLICK_UNIT) {
                    if (Unit.Equals(options.affected, Merge2CupTutorial.tutorialUnit)) {
                        this.finish();
                    }
                }
            }.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
            Merge2CupTutorial.available = false;
        }
    };

    return new TutorialStep(options);
};

Merge2CupTutorial.available = false;