/**
 * Created by Vladislav on 27.03.2025.
 */

var GardenProgressMenubarItem = {
    name: "GardenProgressItem",
    onClickWindow: TravelMapWindow,
    occupySlot: false,
    viewClass: GardenProgressMenubarItemView,
    availableOnScenes: [cleverapps.Environment.SCENE_MAIN]
};