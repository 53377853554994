/**
 * Created by Andrey Popov on 25.02.2025
 */

ScrollableIconTableView.prototype.createItemView = function (customer) {
    return new CustomerRecipeIconView(customer);
};

ScrollableIconTableView.prototype.updateSize = function () {
    this.icons.forEach(function (icon) {
        icon.stopAllActions();
    });

    this.setContentSize2(cleverapps.styles.ScrollableIconTableView.size);

    var direction = cleverapps.UI.VERTICAL;
    var scrollDirection = cleverapps.UI.ScrollView.DIR_VERTICAL;
    var padding = cleverapps.styles.ScrollableIconTableView.padding;
    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
        direction = cleverapps.UI.HORIZONTAL;
        scrollDirection = cleverapps.UI.ScrollView.DIR_HORIZONTAL;
        padding = cleverapps.styles.ScrollableIconTableView.verticalPadding;
    }

    var margin = cleverapps.styles.ScrollableIconTableView.margin[cleverapps.resolution.mode];

    this.iconsLayout.options = cleverapps.clone(this.iconsLayout.options, true);
    this.iconsLayout.setOptions({
        direction: direction,
        margin: margin,
        padding: padding
    });

    this.scrollView.options.direction = scrollDirection;

    this.scrollView.setContentSize(this.getContentSize());
    this.scrollView.setPositionRound(this.width / 2, this.height / 2);

    this.scrollView.updateInnerContent();

    this.iconSlotWidth = this.icons[0].width + margin;
    this.iconSlotHeight = this.icons[0].height + margin;
};

ScrollableIconTableView.prototype.iconRemoveAnimation = function (iconView) {
    var styles = cleverapps.styles.ScrollableIconTableView.animation;

    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
        return new cc.Sequence(
            new cc.DelayTime(1.2),
            new cc.Spawn(
                new cc.MoveBy(0.15, 0, styles.deltaY).easing(cc.easeIn(1)),
                new cc.ScaleBy(0.15, 1.1, 0.9).easing(cc.easeIn(1))
            ),
            new cc.Spawn(
                new cc.MoveBy(0.25, 0, -styles.moveDistanceY).easing(cc.easeIn(2)),
                new cc.ScaleBy(0.1, 0.9, 1.1).easing(cc.easeIn(1))
            )
        );
    }

    var scene = cc.director.getRunningScene();
    var pos = iconView.getParent().convertToNodeSpace(scene.screen.convertToWorldSpace(cc.p(0, 0)));
    var endPos = cc.p(pos.x - iconView.width / 2, iconView.y);

    return new cc.Sequence(
        new cc.DelayTime(1.2),
        new cc.Spawn(
            new cc.MoveBy(0.15, styles.deltaX, 0).easing(cc.easeIn(1)),
            new cc.ScaleBy(0.15, 0.9, 1.1).easing(cc.easeIn(1))
        ),
        new cc.Spawn(
            new cc.MoveTo(0.25, endPos).easing(cc.easeIn(2)),
            new cc.ScaleBy(0.1, 1.1, 0.9).easing(cc.easeIn(1))
        )
    );
};

ScrollableIconTableView.prototype.calculateDelta = function (direction, index) {
    if (direction === "up" && index !== 0) {
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            return cc.p(-this.iconSlotWidth, 0);
        } 
        return cc.p(0, this.iconSlotHeight);
    }
    return cc.p(0, 0);
};

ScrollableIconTableView.prototype.iconMoveAnimation = function (delta) {
    return new cc.Sequence(
        new cc.DelayTime(1.4),
        new cc.MoveBy(0.5, delta).easing(cc.easeBackInOut())
    );
};

ScrollableIconTableView.prototype.addIconAnimation = function (iconView) {
    var styles = cleverapps.styles.ScrollableIconTableView.animation;

    var targetPosition = iconView.getPosition();

    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
        iconView.setPositionRound(iconView.x, iconView.y - styles.moveDistanceY);

        return new cc.Sequence(
            new cc.Spawn(
                new cc.MoveTo(0.15, targetPosition.x, targetPosition.y + styles.deltaY).easing(cc.easeOut(3)),
                new cc.Sequence(
                    new cc.DelayTime(0.12),
                    new cc.ScaleTo(0.03, 1.1, 0.9).easing(cc.easeIn(1))
                )
            ),
            new cc.Spawn(
                new cc.MoveTo(0.15, targetPosition.x, targetPosition.y - styles.deltaY).easing(cc.easeOut(1)),
                new cc.ScaleTo(0.15, 0.95, 1.05).easing(cc.easeOut(1))
            ),
            new cc.Spawn(
                new cc.MoveTo(0.15, targetPosition).easing(cc.easeOut(1)),
                new cc.ScaleTo(0.15, 1, 1).easing(cc.easeIn(1))
            )
        );
    }

    var scene = cc.director.getRunningScene();
    var pos = iconView.getParent().convertToNodeSpace(scene.screen.convertToWorldSpace(cc.p(0, 0)));
    iconView.setPositionRound(pos.x - iconView.width / 2, iconView.y);

    return new cc.Sequence(
        new cc.Spawn(
            new cc.MoveTo(0.15, targetPosition.x + styles.deltaX, targetPosition.y).easing(cc.easeOut(3)),
            new cc.Sequence(
                new cc.DelayTime(0.12),
                new cc.ScaleTo(0.03, 0.9, 1.1).easing(cc.easeIn(1))
            )
        ),
        new cc.Spawn(
            new cc.MoveTo(0.15, targetPosition.x - styles.deltaX, targetPosition.y).easing(cc.easeOut(1)),
            new cc.ScaleTo(0.15, 1.05, 0.95).easing(cc.easeOut(1))
        ),
        new cc.Spawn(
            new cc.MoveTo(0.15, targetPosition).easing(cc.easeOut(1)),
            new cc.ScaleTo(0.15, 1, 1).easing(cc.easeIn(1))
        )
    );
};

ScrollableIconTableView.prototype.updateScrollTouchState = function () {
    this.scrollView.touchScrollDisabled = true;
};

ScrollableIconTableView.prototype.resize = function (callback) {
    callback();
};

ScrollableIconTableView.prototype.scrollTo = function (index, silent, callback) {
    callback && callback();
};

cleverapps.overrideStyles(cleverapps.styles.ScrollableIconTableView, {
    clipping: false,

    animation: {
        deltaX: 35,
        deltaY: 25,
        moveDistanceY: 300
    },

    size: [
        {
            width: 700,
            height: 160
        },
        {
            width: 500,
            height: 899
        },
        {
            width: 500,
            height: 899
        }
    ],

    margin: [
        25,
        60,
        60
    ],

    fakeIconSize: [
        {
            width: 210,
            height: 145
        },
        {
            width: 500,
            height: 213
        },
        {
            width: 500,
            height: 213
        }
    ],

    padding: {
        x: 0,
        y: 30
    },

    verticalPadding: {
        x: 10,
        y: 0
    }
});
