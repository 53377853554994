/**
 * Created by anatoly on 03.04.2025
 */

var RepairHintLogic = {
    type: "fix",

    findMove: function () {
        var location = cleverapps.meta.getSelectedLocation();
        if (location.stars < location.getCurrentStagePrice()) {
            return;
        }

        var scene = cleverapps.scenes.getRunningScene();
        var roomView = scene.getRoomView();

        return {
            type: RepairHintLogic.type,
            options: {
                target: location.isGameMode() ? scene.getMetaSwitchButton() : roomView.getRepairView()
            }
        };
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(RepairHintLogic);
