/**
 * Created by anatoly on 31.01.2025
 */

var CustomerRecipeIconView = cc.Node.extend({
    ctor: function (customer) {
        this._super();

        this.persType = CustomerRecipeIconView.PERS_TYPES[Object.keys(CustomerRecipeIconView.PERS_TYPES)[customer.unit.x]];
        this.customer = customer;
        this.recipe = this.customer.recipe;

        this.resolutionMode = cleverapps.resolution.mode;

        var styles = cleverapps.styles.CustomerRecipeIconView;

        this.units = {};

        this.setContentSize2(styles.size);
        this.setAnchorPoint(0.5, 0.5);

        this.setBg();

        this.addRewards();
        this.addIngredients();
        this.addPortraits();

        this.checkIngredients();

        this.updateSize();

        this.customer.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        Map2d.currentMap.customers.on("onRecipesUpdated", this.checkIngredients.bind(this), this);
    },

    setBg: function () {
        var currentAnimation;
        if (this.bg) {
            currentAnimation = this.bg.getCurrentAnimationName(0);
            this.bg.removeFromParent();
        }
        if (this.fg) {
            this.fg.removeFromParent();
        }

        var bundle = bundles.questsgroupicon.jsons.bg_default;
        if (this.resolutionMode === cleverapps.WideMode.VERTICAL) {
            bundle = bundles.questsgroupicon.jsons.bg_vertical;
        }

        var bg = this.bg = new cleverapps.Spine(bundle);

        bg.setAnimation(0, currentAnimation || "idle", true);

        bg.setPositionRound(this.width / 2, this.height / 2);

        this.addChild(bg, -1);

        if (this.resolutionMode !== cleverapps.WideMode.VERTICAL) {
            var patchSlot = this.bg.getSlot("slot_patch");
            var fg = this.fg = new cleverapps.Spine(bundles.questsgroupicon.jsons.fg_patch);
            fg.setAnimation(0, "animation", false);
            patchSlot.addChild(fg);
            patchSlot.setLocalZOrder(1);
        }
    },

    addRewards: function () {
        var styles = cleverapps.styles.CustomerRecipeIconView.rewards;

        if (this.rewardsList) {
            this.rewardsList.removeFromParent();
        }

        var rewardSlot = this.bg.getSlot("slot_reward");

        var rewardsList = this.rewardsList = new RewardsListComponent(this.recipe.prizesToRewards(), {
            small: true,
            iconWrap: styles.iconWrap,
            noPrefix: true,
            textDirection: cleverapps.UI.HORIZONTAL,
            textMargin: styles.margin,
            margin: styles.rewardsMargin,
            fitToBox: { width: rewardSlot.width, height: rewardSlot.height },
            font: cleverapps.styles.FONTS.REWARD_TEXT,
            event: cleverapps.EVENTS.EARN.LEVEL_REWARD
        });

        rewardSlot.addChild(rewardsList);
        rewardsList.setPositionRound(rewardSlot.width / 2, rewardSlot.height / 2);
    },

    addIngredients: function () {
        var single = [{
            item: "slot_item_center",
            checkMark: "slot_checkmark_center"
        }];

        var pair = [{
            item: "slot_item_left",
            checkMark: "slot_checkmark_left"
        }, {
            item: "slot_item_right",
            checkMark: "slot_checkmark_right"
        }];

        var slots = this.recipe.getIngredients().length === 2 ? pair : single;
        var unusedSlots = slots === pair ? single : pair;

        unusedSlots.forEach(function (unusedSlot) {
            var slotNames = [unusedSlot.item, unusedSlot.checkMark];
            slotNames.forEach(function (slotName) {
                this.bg.getSlot(slotName).addChild(new cc.Node());
            }.bind(this));
        }.bind(this));

        this.ingredients = this.recipe.getIngredients().map(function (ingredient, index) {
            var itemSlot = this.bg.getSlot(slots[index].item);
            var checkMarkSlot = this.bg.getSlot(slots[index].checkMark);

            var view = new OrderIngredientView(ingredient, {
                size: {
                    width: itemSlot.width,
                    height: itemSlot.height
                },
                checkMark: {
                    x: itemSlot.width / 2 + (checkMarkSlot.x - itemSlot.x),
                    y: itemSlot.height / 2 + (checkMarkSlot.y - itemSlot.y),
                    width: checkMarkSlot.width,
                    height: checkMarkSlot.height
                }
            });

            itemSlot.addChild(view);
            view.setPositionRound(itemSlot.width / 2, itemSlot.height / 2);
            view.basePosition = view.getPosition();

            checkMarkSlot.addChild(new cc.Node());

            return view;
        }.bind(this));
    },

    addPortraits: function () {
        if (this.resolutionMode === cleverapps.WideMode.VERTICAL) {
            if (this.pers) {
                this.pers.removeFromParent();
                delete this.pers;
            }

            return;
        }

        if (this.pers) {
            return;
        }

        var pers = this.pers = new cc.Sprite(bundles.questsgroupicon.frames["pers_" + this.persType]);
        var portraitSlot = this.bg.getSlot("slot_portrait");
        portraitSlot.addChild(pers);
    },

    checkIngredients: function () {
        var hasEnough = this.recipe.isEnoughIngredients();

        this.ingredients.forEach(function (ingredient) {
            ingredient.refresh(hasEnough);
        });

        if (hasEnough) {
            this.setReady();
        } else {
            this.setNotReady();
        }
    },

    setReady: function () {
        this.bg.setAnimation(0, "complete", true);

        this.createReadyButton();

        if (!this.iconClickHandler) {
            this.iconClickHandler = cleverapps.UI.onClick(this, function () {
                cleverapps.audio.playSound(bundles.main.urls.click_effect);

                this.readyClickHandler();
            }.bind(this), {
                interactiveScale: false
            });
        }

        this.showForce();
    },

    setNotReady: function () {
        this.bg.setAnimation(0, "idle", true);

        this.removeReadyButton();

        if (this.iconClickHandler) {
            this.iconClickHandler.remove();
            delete this.iconClickHandler;
        }
    },

    createReadyButton: function () {
        if (this.readyButton) {
            return;
        }

        var styles = cleverapps.styles.CustomerRecipeIconView;

        var buttonSize = styles.readyButton.size[this.resolutionMode];

        var button = this.readyButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_green,
            width: buttonSize.width,
            height: buttonSize.height,
            text: "CustomerRecipeIconView.buttonText",
            onClicked: this.readyClickHandler.bind(this)
        });

        button.text.setFontSize(styles.readyButton.fontSize[this.resolutionMode]);

        this.updateReadyButton();
    },

    updateReadyButton: function () {
        if (!this.readyButton) {
            return;
        }

        if (this.readyButton.parent) {
            this.readyButton.removeFromParent(false);
        }

        var buttonSlot = this.bg.getSlot("slot_button");
        buttonSlot.addChild(this.readyButton);
        buttonSlot.setLocalZOrder(1);

        var styles = cleverapps.styles.CustomerRecipeIconView;

        var buttonSize = styles.readyButton.size[this.resolutionMode];
        this.readyButton.resize(buttonSize.width, buttonSize.height);
        this.readyButton.text.setFontSize(styles.readyButton.fontSize[this.resolutionMode]);
    },

    readyClickHandler: function () {
        this.stopAllActions();
        this.removeReadyButton();

        if (cleverapps.forces.isRunningForce(Forces.COMPLETE_ORDER_FORCE)) {
            cleverapps.forces.closeRunningForce();
        }

        this.rewardsList.receiveRewards();
        this.rewardsList.receiveRewardsAnimation({
            noWinEffect: true,
            delay: 800
        });
            
        this.customer.exchange(function () {
            if (!this.isRunning()) {
                return;
            }

            this.ingredients.forEach(function (ingredient) {
                ingredient.playIconBounceAnimation();
            });
            this.runAction(new cc.Sequence(
                new cc.ScaleTo(0.1, 0.94).easing(cc.easeOut(1)),
                new cc.ScaleTo(0.15, 1.04).easing(cc.easeInOut(1)),
                new cc.ScaleTo(0.22, 0.98).easing(cc.easeInOut(1)),
                new cc.ScaleTo(0.27, 1).easing(cc.easeInOut(1))
            ));
        }.bind(this));
    },

    removeReadyButton: function () {
        if (!this.readyButton) {
            return;
        }

        var readyButton = this.readyButton;
        delete this.readyButton;

        readyButton.disable();
        readyButton.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.15, 0.2),
                new cc.FadeOut(0.15)
            ),
            new cc.RemoveSelf()
        ));
    },

    collectUnitAnimation: function (unit, callback) {
        var unitView = unit.onGetView();

        var targetView = this.ingredients.find(function (view) {
            return Unit.Equals(view.getIngredientUnit(), unit);
        });

        if (!unitView || !targetView) {
            callback();
            return;
        }

        unitView.runAction(new cc.Sequence(
            StandartAnimations.animateCollect(unitView, targetView, {
                sound: bundles.main.urls.win_reward_effect,
                scale: 0.6
            }),
            new cc.RemoveSelf()
        )).setFinalize(callback);
    },

    showForce: function () {
        if (this.readyButton && !cleverapps.forces.isShown(Forces.COMPLETE_ORDER_FORCE.id)) {
            cleverapps.focusManager.display({
                focus: "CompleteOrderForce",
                action: function (f) {
                    cleverapps.forces.create(this.readyButton, Forces.COMPLETE_ORDER_FORCE, {
                        highlights: [this, Map2d.currentMap.getMapView()],
                        importantNodes: [this]
                    });
                    cleverapps.forces.onceForceClosed = f;
                }.bind(this)
            });
        }
    },

    updateSize: function () {
        if (this.resolutionMode === cleverapps.resolution.mode) {
            return;
        }

        this.resolutionMode = cleverapps.resolution.mode;

        var styles = cleverapps.styles.CustomerRecipeIconView;
        this.setContentSize2(styles.size);

        this.setBg();
        this.addRewards();
        this.addIngredients();

        if (this.pers) {
            this.pers.removeFromParent();
            delete this.pers;
        }
        this.addPortraits();
        this.updateReadyButton();
    }
});

CustomerRecipeIconView.PERS_TYPES = {};
CustomerRecipeIconView.PERS_TYPES.FIRST = 0;
CustomerRecipeIconView.PERS_TYPES.SECOND = 1;
CustomerRecipeIconView.PERS_TYPES.THIRD = 2;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    REWARD_TEXT: {
        name: "default",
        size: 45
    }
});

cleverapps.styles.CustomerRecipeIconView = {
    size: [
        {
            width: 210,
            height: 145
        },
        {
            width: 500,
            height: 213
        },
        {
            width: 500,
            height: 213
        }
    ],

    readyButton: {
        size: [
            {
                width: 130,
                height: 30
            },
            {
                width: 240,
                height: 60
            },
            {
                width: 240,
                height: 60
            }
        ],

        fontSize: [
            20,
            35,
            35
        ]
    },

    rewards: {
        iconWrap: {
            width: 45,
            height: 45
        },

        margin: 3,
        rewardsMargin: { x: 55 }
    }
};
