/**
 * Created by Denis Kuzin on 21 march 2025
 */

Collectible.prototype.needCollectConfirmation = cleverapps.extendFunc(Collectible.prototype.needCollectConfirmation, function () {
    if (Map2d.currentMap.unitInfo) {
        var selection = Map2d.currentMap.unitInfo.lastSelection;
        return !(selection && this.unit.x === selection.x && this.unit.y === selection.y);
    }

    return this._super.apply(this, arguments);
});