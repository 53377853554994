/**
 * Created by Andrey Popov on 31.03.2025
 */

FogBlock.prototype.scrollAndUpdateStateAction = function (newState) {
    this.trigger("updateBlocker", newState);
    this.updateState(newState, false, true);

    this.trigger("updateState", newState);

    if (this.state >= FogBlock.CANTOPEN) {
        this.unlock();
        this.fogs.calcFogStatesStage();
    }
};