/**
 * Created by Andrey Popov on 12/5/24.
 */

GeneratorSourceMine.prize = function (level, unit) {
    var probabilities = [];
    var units = [];

    var allResources = unit.getResource();

    var giveUnopened = unit.getFamily().giveUnopened;

    var resources = allResources;
    if (!giveUnopened) {
        resources = allResources.filter(function (resource) {
            return cleverapps.unitsLibrary.hasOpened(resource);
        });

        if (resources.length === 0) {
            resources = [allResources[0]];
        }
    }

    var resourceProbabilities = GeneratorSourceMine.calculateResourceProbabilities(resources);

    resources.forEach(function (resource) {
        probabilities.push(resourceProbabilities[resource]);
        units.push({ code: resource, stage: 0 });
    });

    return [{
        amount: 1,
        probabilities: probabilities,
        units: units
    }];
};

GeneratorSourceMine.calculateResourceProbabilities = function (targetResources) {
    var data = Merge.currentMerge.orderManager.orders;

    var resourceCounts = {};
    var totalWeight = 0;
    var currentResourceCode, currentStage, resourceWeight;

    for (var i = 0; i < targetResources.length; i++) {
        resourceCounts[targetResources[i]] = 0;
    }

    for (var orderIndex = 0; orderIndex < data.length; orderIndex++) {
        var orderIngredients = data[orderIndex].ingredients;

        for (var ingredientIndex = 0; ingredientIndex < orderIngredients.length; ingredientIndex++) {
            currentResourceCode = orderIngredients[ingredientIndex].code;
            currentStage = orderIngredients[ingredientIndex].stage || 0;
            resourceWeight = Math.pow(2, currentStage);

            if (resourceCounts[currentResourceCode] !== undefined) {
                resourceCounts[currentResourceCode] += resourceWeight;
                totalWeight += resourceWeight;
            }
        }
    }

    var probabilities = {};
    for (var resourceIndex = 0; resourceIndex < targetResources.length; resourceIndex++) {
        currentResourceCode = targetResources[resourceIndex];
        probabilities[currentResourceCode] = totalWeight > 0
            ? resourceCounts[currentResourceCode] / totalWeight
            : 0;
    }

    return probabilities;
};

Mines.generator = GeneratorSourceMine.prize;
Mines.mineable = GeneratorSourceMine.prize;