/**
 * Created by Denis Kuzin on 13 january 2025
 */

var GroundedView = cc.Node.extend({
    ctor: function (grounded, unitView) {
        this._super();

        var pos = cc.p(grounded.unit.x, grounded.unit.y);

        var type = Map2d.currentMap.getTerrainType(pos.x, pos.y);
        var fogType = (bundles[type.name] || bundles[type.defaultTiles]).meta.fog;
        var spine = this.spine = new cleverapps.Spine(bundles["fogs_" + fogType].jsons["ground_fog_json_" + fogType]);

        spine.setAnimation(0, "idle", true);
        unitView.setImageOpacity(160);

        if (Map2d.currentMap.isFlat) {
            var mapView = Map2d.currentMap.getMapView();
            mapView.alignViewInGrid(pos.x, pos.y, spine);
            mapView.animations.addChild(spine);
        } else {
            unitView.addChild(spine);
            spine.setPositionRound(unitView.width / 2, unitView.height / 2);
        }
    },

    onRemove: function (silent) {
        if (silent) {
            this.removeFromParent();
            this.spine.removeFromParent();
        }

        this.spine.setAnimation(0, "close", true);
        this.spine.setCompleteListenerRemove(function () {
            this.removeFromParent();
        }.bind(this));
    },

    highlight: function () {

    },

    unhighlight: function () {

    }
});