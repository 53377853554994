/**
 * Created by anatoly on 21.01.2025
 */

UnitView.prototype.handleClick = function () {
    if (!this.isClickable()) {
        return false;
    }

    Map2d.currentMap.selectUnit(this.unit);

    if (this.unit.handleClick()) {
        return true;
    }

    this.unit.squeeze();
    cleverapps.audio.playSound(bundles.game.urls.click_field_effect);

    return true;
};

UnitView.prototype.merge = function () {
    var mapView = Map2d.currentMap.getMapView();
    this.replaceParentSamePlace(mapView.animations);

    this.runAction(new cc.Sequence(
        new cc.ScaleTo(0.15, 0.7).easing(cc.easeBackIn()),
        new cc.RemoveSelf()
    ));
};

UnitView.prototype.startDidMerged = function () {
    var styles = cleverapps.styles.UnitView;

    var mapView = Map2d.currentMap.getMapView();

    var particles = new cleverapps.Spine(bundles.merge_animations.jsons.merge_particles);
    particles.setPositionRound(styles.mergeAnimationBelow);
    particles.setCompleteListenerRemove();
    particles.setSafeToRemove();
    mapView.addTile(Map2d.LAYER_UNITS, this.unit.x, this.unit.y, particles);

    particles.setAnimation(0, this.chooseMergeParticlesAnimation(particles), false);
};

UnitView.prototype.chooseMergeParticlesAnimation = function (particles) {
    var listAnimations = particles.listAnimations();

    if (listAnimations.length === 1) {
        return listAnimations[0];
    }

    if (this.unit.stage >= 7) {
        return listAnimations[2];
    }
    if (this.unit.stage >= 4) {
        return listAnimations[1];
    }

    return listAnimations[0];
};

UnitView.prototype.didMerged = function () {
    this.stopAllActions();
    this.removePath();

    var parent = this.getParent();
    this.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this), {
        keepScale: true
    });

    this.setVisible(false);

    if (this.markLastAnimation) {
        this.markLastAnimation.setVisible(false);
    }

    this.setScale(0.7);

    this.mergeAction = this.runAction(new cc.Sequence(
        new cc.DelayTime(0.15),
        new cc.Show(),
        new cc.ScaleTo(0.15, 1.15).easing(cc.easeInOut(2)),
        new cc.ScaleTo(0.15, 1).easing(cc.easeInOut(2))
    )).setFinalize(function () {
        this.replaceParentSamePlace(parent, {
            keepScale: true
        });

        if (this.markLastAnimation) {
            this.markLastAnimation.setAnimationAndIdleAfter("showup", "idle");
            this.markLastAnimation.setVisible(true);
        }
    }.bind(this));
};

UnitView.prototype.updateCustomerMark = function () {
    if (cleverapps.config.demoMode || cleverapps.gameModes.hideGuideAndProgress) {
        return;
    }

    if (this.unit.customerMark) {
        var styles = cleverapps.styles.UnitView;
        var markBg = this.markBg = new cc.Sprite(bundles.merge.frames.customer_check_mark_bg);
        markBg.setAnchorPoint(0, 0);
        markBg.setPositionRound(styles.mark);

        var mark = new cc.Sprite(bundles.merge.frames.customer_check_mark_flat);
        markBg.addChild(mark);
        mark.setPositionRound(markBg.width / 2, markBg.height / 2);

        this.addChild(markBg);

        var unitMarkerUnderlay = new cc.Sprite(bundles.merge.frames.unit_marker_underlay);
        unitMarkerUnderlay.setPositionRound(this.width / 2, this.height / 2);

        this.animateAppearAdditionalView("unitMarkerUnderlay", unitMarkerUnderlay);
    } else if (this.markBg) {
        this.markBg.removeFromParent();
        delete this.markBg;

        this.removeAdditionalView("unitMarkerUnderlay");
    }
};

cleverapps.overrideStyles(cleverapps.styles.UnitView, {
    mark: {
        x: { align: "center", dx: 35 },
        y: { align: "bottom", dy: -5 }
    }
});