/**
 * Created by Denis Kuzin on 24 december 2024
 */

Families.initialize = function () {
    Families.unknown = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        movable: false
    }, {
        expeditions: "all"
    });

    Families.Y = FamiliesHelper.ValuableFamily("Y", { amounts: [2, 5, 15, 40, 100], collectible: { rewardType: "energy" } });
    Families.X = FamiliesHelper.ValuableFamily("X", { amounts: [1, 2, 6, 20, 60], collectible: { rewardType: "rubies" } });

    Families.a = FamiliesHelper.HeartsResourceFamily(7);
    Families.b = FamiliesHelper.HeartsResourceFamily(6);
    Families.c = FamiliesHelper.HeartsResourceFamily(6);
    Families.d = FamiliesHelper.HeartsResourceFamily(8);

    Families.V = FamiliesHelper.HeartsGeneratorFamily({
        stages: 4,
        resource: ["a", "b"]
    });
    Families.U = FamiliesHelper.HeartsGeneratorFamily({
        stages: 4,
        resource: ["c", "d"],
        giveUnopened: true
    });

    Families.A = FamiliesHelper.EnergyCupFamily(1, {
        price: [30],
        amount: [3],
        probabilities: [
            [0.6, 0.3, 0.1]
        ],
        rewards: [{ code: "Y", stage: 0 }, { code: "Y", stage: 1 }, { code: "Y", stage: 2 }],
        firstReward: [{ code: "b", stage: 0 }, { code: "U", stage: 3 }]
    });

    Families.z = FamiliesHelper.HeartsCustomerFamily();
    Families.afterInitialize();
};

if (typeof cc === "undefined") {
    module.exports = Families;
}
