/**
 * Created by Andrey Popov on 3/28/25.
 */

Map2d.prototype.initFocusCell = function () {
    this.scroller.zoom = 1.4;
    this.scroller.setScrollCell({
        x: 41,
        y: 38
    });
};