/**
 * Created by Vladislav on 27.03.2025.
 */

var OrderStarsMenuBarItem = {
    name: "OrderStarsItem",
    targets: ["orderStar"],
    filter: function () {
        return false;
    },
    value: function () {
        return cleverapps.meta.getSelectedLocation().stars;
    },
    updater: function (f) {
        cleverapps.meta.getSelectedLocation().on("changeStars", f, this);
    },
    icon: bundles.menubar.frames.order_star_png,
    availableOnScenes: [cleverapps.Environment.SCENE_MAIN]
};