/**
 * Created by razial on 03.04.2025.
 */

Placements.ENTRIES["gardenMoveNext"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 10,

    filter: function () {
        var location = cleverapps.meta.getSelectedLocation();
        var nextLocationId = cleverapps.meta.nextLocationId(location.locationId);

        return location.isCompleted()
            && !cleverapps.meta.listActiveLocations().includes(nextLocationId)
            && !cleverapps.meta.isCompleted();
    },

    action: function () {
        cleverapps.meta.getSelectedLocation().moveNextLocation();
    }
};
