/**
 * Created by anatoly on 23.03.2025
 */

var OrderHintLogic = {
    type: "order",

    findMove: function () {
        var customer = Map2d.currentMap.customers.findCanCook();
        if (!customer) {
            return;
        }

        var scene = cleverapps.scenes.getRunningScene();
        var ordersGroup = scene.getOrdersGroupIconView();
        if (!ordersGroup) {
            return;
        }

        var recipeIcon = ordersGroup.icons.find(function (icon) {
            return icon.customer === customer;
        });

        if (recipeIcon && recipeIcon.readyButton) {
            return {
                type: OrderHintLogic.type,
                options: {
                    recipeIcon: recipeIcon.readyButton
                }
            };
        }
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(OrderHintLogic);