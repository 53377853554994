/**
 * Created by olga on 23.01.2025
 */

var Merge2BaseTutorial = function () {
    return new TutorialScenario("merge2_base_tutorial", {
        listSteps: Merge2BaseTutorial.listSteps,
        onStart: function () {
            cleverapps.focusManager.showControlsWhileFocused("map2d");
        },
        onFinish: function () {
            cleverapps.focusManager.hideControlsWhileFocused("map2d");
        },
        isAvailable: function () {
            return false;
        }
    });
};

Merge2BaseTutorial.listSteps = function () {
    var steps = [];
    Merge2BaseTutorial.STEPS.forEach(function (step) {
        if (step.merge) {
            steps.push(Merge2BaseTutorial.createMergeStep(step));
        }
        if (step.mine) {
            steps.push(Merge2BaseTutorial.createMineStep(step));
        }
        if (step.order) {
            steps.push(Merge2BaseTutorial.createOrderStep(step));
        }
    });
    return steps;
};

Merge2BaseTutorial.createMineStep = function (data) {
    return new TutorialStep({
        onStart: function () {
            this.units = Map2d.currentMap.listAvailableUnits(data.unit).slice(0, 1);

            this.force = cleverapps.forces.create(Map2d.currentMap.getMapView(), {
                text: data.text || "",
                finger: { tap: this.units[0].onGetView() }
            });
            cleverapps.tutorial.on("mine", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
        }
    });
};

Merge2BaseTutorial.createOrderStep = function (data) {
    return new TutorialStep({
        onStart: function () {
            var orderIconView = cleverapps.scenes.getRunningScene().ordersGroupIconViewControl;
            this.customerUnit = Map2d.currentMap.customers.listCustomersWithRecipe()[0].unit;

            var highlights = orderIconView ? [Map2d.currentMap.getMapView()] : [];

            this.force = cleverapps.forces.create(orderIconView || Map2d.currentMap.getMapView(), {
                text: data.text || "",
                delay: 0.5,
                position: MinimalDialogue.POSITIONS.BOTTOM,
                finger: { tap: orderIconView ? orderIconView.target.icons[0].readyButton : this.customerUnit.onGetView().getAdditionalView("ingredients") }
            }, {
                highlights: highlights
            });
            
            cleverapps.tutorial.on("completeOrderStep", this.finish.bind(this), this);
        },

        onFinish: function () {
            Map2dScroller.currentScroller.zoomOut();
            this.force.hideForceHints();
            Merge.currentMerge.counter.trigger();
        }
    });
};

Merge2BaseTutorial.createMergeStep = function (data) {
    var options = {
        onStart: function () {
            var units = Map2d.currentMap.listAvailableUnits(data.unit);
            var groundedUnits = units.filter(function (unit) {
                return unit.isGrounded();
            });
            var notGroundedUnits = units.filter(function (unit) {
                return !unit.isGrounded();
            });
            this.units = data.noGroundedFirst
                ? [notGroundedUnits[0] || groundedUnits[0], notGroundedUnits[1] || groundedUnits[1]]
                : [notGroundedUnits[0], groundedUnits[0] || notGroundedUnits[1]];

            var unitViews = this.units.map(function (unit) {
                return unit.onGetView();
            });

            this.force = cleverapps.forces.create(Map2d.currentMap.getMapView(), {
                text: data.text || "",
                finger: {
                    swipe: unitViews,
                    getPayload: function () {
                        var unitView = UnitView.getUnitImage(unitViews[0].unit);
                        unitView.setPosition(unitView.getTargetPosition(unitViews[0]));
                        return unitView;
                    },
                    payloadScale: 1
                }
            });

            var unit = this.units[0];
            var mergeComponent = unit.findComponent(MergeComponent);

            var cells = this.units.map(function (unit) {
                return cc.p(unit.x, unit.y);
            });

            mergeComponent.highlightMergeHint(cells);

            Map2d.currentMap.on("changeDragging", function (unit) {
                if (unit) {
                    if (this.saveTimeout) {
                        cleverapps.timeouts.clearTimeout(this.saveTimeout);
                        this.saveTimeout = undefined;
                    }

                    mergeComponent.unhighlightMerge();
                    this.force.stopFinger();
                } else {
                    this.saveTimeout = cleverapps.timeouts.setTimeout(function () {
                        cells = this.units.map(function (unit) {
                            return cc.p(unit.x, unit.y);
                        }).filter(function (cell) {
                            return Map2d.currentMap.getUnit(cell.x, cell.y);
                        });

                        if (cells.length > 1) {
                            mergeComponent.highlightMergeHint(cells);
                        }
                    }.bind(this), 500);

                    this.force.resumeFinger();
                }
            }.bind(this), this);

            cleverapps.tutorial.on("onResize", function () {
                mergeComponent.highlightMergeHint(cells);
            });

            cleverapps.tutorial.on("merge", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
        }
    };

    return new TutorialStep(options);
};

Merge2BaseTutorial.STEPS = [
    {
        merge: true,
        unit: { code: "V", stage: 0 },
        text: "Merge2.BaseTutorial0"
    },
    {
        merge: true,
        unit: { code: "V", stage: 1 },
        text: "Merge2.BaseTutorial1"
    },
    {
        merge: true,
        unit: { code: "V", stage: 2 },
        text: "Merge2.BaseTutorial2"
    },
    {
        mine: true,
        unit: { code: "V", stage: 3 },
        text: "Merge2.BaseTutorial3"
    },
    {
        mine: true,
        unit: { code: "V", stage: 3 },
        text: "Merge2.BaseTutorial3_1"
    },
    {
        merge: true,
        unit: { code: "a", stage: 0 },
        noGroundedFirst: true,
        text: "Merge2.BaseTutorial4"
    },
    {
        merge: true,
        unit: { code: "a", stage: 1 },
        text: "Merge2.BaseTutorial5"
    },
    {
        order: true,
        text: "Merge2.BaseTutorial6"
    }
];