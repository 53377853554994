/**
 * Created by mac on 12/23/20
 */

UpgradableView.prototype.createGuideButton = function () {
};

UpgradableView.prototype.createProgress = cleverapps.extendFunc(UpgradableView.prototype.createProgress, function () {
    this._super.apply(this, arguments);

    var styles = cleverapps.styles.UpgradableView;

    var icon = this.icon = new cc.Sprite(bundles.merge.frames.order_star);
    icon.setPositionRound(styles.level);
    icon.setLocalZOrder(1);
    this.progress.addChild(icon);

    if (this.upgradable.nextStageReward[0]) {
        var rewardIcon = UnitView.getUnitImage(this.upgradable.nextStageReward[0], {
            preferStatic: true,
            useLibraryAnchors: true
        });
        rewardIcon.setPositionRound(styles.rewardIcon);
        rewardIcon.setLocalZOrder(1);
        this.progress.addChild(rewardIcon);
        cleverapps.UI.fitToBox(rewardIcon, { width: styles.rewardIcon.width, height: styles.rewardIcon.height });
    }
});

cleverapps.overrideStyles(cleverapps.styles.UpgradableView, {
    rewardIcon: {
        x: { align: "center", dx: 104 },
        y: { align: "center", dy: 0 },
        width: 80,
        height: 80
    }
});
