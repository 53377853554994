/**
 * Created by Denis Kuzin on 15 january 2025
 */

Grounded.prototype.handleClick = function () {
    return true;
};

Grounded.prototype.handleRemove = function () {
    this.remove();
    if (Map2d.currentMap.blockedGrounds) {
        Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
    }
};