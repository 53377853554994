/**
 * Created by anatoly on 02.03.2025
 */

var Map2dFlatNode = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.mapView = new Map2dFlatView(Map2d.currentMap);
        this.addChild(this.mapView);

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var needRotateMap = cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL;

        if (needRotateMap !== this.mapView.isRotated()) {
            this.mapView.rotate();
        }

        var styles = cleverapps.styles.Map2dFlatView;

        var mapSize = this.mapView.getSize();
        var padding = styles.borderPadding * 4;

        var widthDiff = cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL ? cleverapps.styles.Map2dFlatView.cell.width : 0;
        var heightDiff = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? cleverapps.styles.Map2dFlatView.cell.height : 0;
        this.setContentSize(mapSize.width + padding - widthDiff, mapSize.height + padding - heightDiff);

        this.setScale(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? 0.65 : 1);
    },

    setupChildren: function () {
        var offsetX = cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL ? cleverapps.styles.Map2dFlatView.cell.width / 2 : 0;
        var offsetY = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? cleverapps.styles.Map2dFlatView.cell.height / 2 : 0;
        this.mapView.setPositionRound(this.width / 2 + offsetX, this.height / 2 + offsetY);
    }
});