/**
 * Created by Vladislav on 01.04.2025.
 */

var RoomView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        var locationId = cleverapps.meta.selectedLocationId();
        var location = cleverapps.meta.getSelectedLocation();

        this.items = [];
        for (var i = 0; i < location.totalStages; i++) {
            var spine = new cleverapps.Spine(bundles["room_" + locationId].jsons["item_" + i]);
            this.addChild(spine, RoomView.STAGE_ORDERS[i] || 0);
            spine.setAnimation(0, i < location.stage ? "idle_1" : "idle_0", true);

            this.items.push(spine);
        }

        var repairView = new RoomRepairView();
        this.addChild(repairView, 10);

        this.repairView = new HidingNode(repairView);
        cleverapps.focusManager.registerControl("roomRepairView", this.repairView);

        this.updateRepairView();

        location.on("nextStage", this.onNextStage.bind(this), this);

        if (cleverapps.config.debugMode) {
            location.on("reset", function () {
                this.items.forEach(function (item) {
                    item.setAnimation(0, "idle_0", true);

                    cleverapps.focusManager.registerControl("roomRepairView", this.repairView);

                    this.updateRepairView();
                }, this);
            }.bind(this), this);
        }
    },

    getRepairView: function () {
        return this.repairView;
    },

    updateRepairView: function () {
        var location = cleverapps.meta.getSelectedLocation();

        if (location.isCompleted()) {
            cleverapps.focusManager.removeControl("roomRepairView");
            this.repairView.hide(true);
        } else {
            var slot = this.items[location.stage].getSlot("slot_item");
            var slotPosition = slot.getParent().convertToWorldSpace(slot.getPosition());

            slotPosition = this.repairView.getParent().convertToNodeSpace(slotPosition);

            this.repairView.setPosition(slotPosition);
        }
    },

    onNextStage: function (stage) {
        this.items[stage - 1].setAnimationAndIdleAfter("transition", "idle_1");

        var location = cleverapps.meta.getSelectedLocation();

        this.repairView.hide();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.0),
            new cc.CallFunc(function () {
                this.updateRepairView();

                if (!location.isCompleted()) {
                    this.repairView.show();
                }
            }, this)
        ));
    }
});

RoomView.STAGE_ORDERS = [1, 4, 3, 2, 0, 0, 0, 4, -1, 4, 3, 3, 2, 4, 4, 3, 3, 5];

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    REPAIR_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.RoomView = {};