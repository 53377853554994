/**
 * Created by Denis Kuzin on 11 december 2024
 */

var StopGardenFeatures = function () {
    cleverapps.gameModes.noSidebar = true;
    cleverapps.gameModes.skipQuests = true;
    cleverapps.gameModes.skipTutorials = true;
    cleverapps.gameModes.silentCustomers = true;

    for (var code in ChestListPrize) {
        var chest = ChestListPrize[code];
        for (var stage in chest) {
            delete chest[stage].cinema;
        }
    }

    delete Offers[Offers.TYPES.SOFTFEAST];
    delete Offers[Offers.TYPES.KRAKENFEAST];
    delete Offers[Offers.TYPES.UNDERSEA];

    var nonExistent = [
        Mission.TYPE_SOFTFEAST, Mission.TYPE_CHESTTIME, Mission.TYPE_KRAKENFEAST, Mission.TYPE_SALEPASS, Mission.TYPE_CARAVAN
    ];

    delete Landmarks.WORLDS.main;

    Season.AVAILABLE = {
        level: 100
    };

    nonExistent.forEach(function (id) {
        delete Missions[id];
    });
};

var DeletedFamilies = ["snow", "lava", "sand", "asia", "warcraft", "steampunk", "moonstone",
    "rubiesplant", "coinsplant", "energyplant", "cinema", "thanksgiving", "kraken",
    "knight", "hunter", "centaur", "gnomes", "shark", "fish", "panda",
    "season0", "season1", "season2", "season3", "season4", "season5", "season6", "season7", "season8", "season9",
    "season10", "season11", "caravanship"
];

if (typeof cc === "undefined") {
    module.exports = DeletedFamilies;
}