/**
 * Created by anatoly on 16.01.2025
 */

Merge.prototype.playMergeSound = function (options) {
    var sound = bundles.merge.urls["merge_effect_" + Math.min(options.stage, 5)];
    if (sound) {
        cleverapps.audio.playSound(sound);
    }
};

Merge.BASE_SPAWN_DELAY = 300;