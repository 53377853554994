/**
 * Created by anatoly on 27.02.2025
 */

MineHintLogics = {
    type: "mine",

    findMove: function () {
        if (!MineHintLogics.canFindMove()) {
            return;
        }

        var mineable = cleverapps.Random.choose(Map2d.currentMap.listAvailableUnits().filter(function (unit) {
            return unit.findComponent(Mineable);
        }));

        if (mineable) {
            return {
                type: MineHintLogics.type,
                options: {
                    cell: cc.p(mineable.x, mineable.y)
                }
            };
        }
    },

    canFindMove: function () {
        var map = Map2d.currentMap;

        return map && map.countEmptySlots()
            && !cleverapps.lives.isEmpty();
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(MineHintLogics);