/**
 * Created by Denis Kuzin on 09 january 2025
 */

FogTileView.prototype.startFogDisappearAnimation = function () {
    var styles = this.getStyles();

    this.tileView.setAnchorPoint(0.5, 0.5);

    this.fogAnimation = new cleverapps.Spine(this.getFogAnimation());
    this.fogAnimation.setAnimation(0, "idle", true);

    this.setHighlightAnimation();
    this.addGroundBg();

    this.fogAnimation.setPositionRound(styles.groundFog);
    this.tileView.addChild(this.fogAnimation);
};

FogTileView.prototype.setHighlightAnimation = function () {
    if (cleverapps.config.name === "hearts") {
        return;
    }

    this.runAction(new cc.Sequence(
        new cc.DelayTime(this.getHighlightAnimationDelay()),
        new cc.CallFunc(function () {
            if (FogTileView.nextBoxAnimationTime > Date.now()) {
                this.setHighlightAnimation();
                return;
            }

            FogTileView.nextBoxAnimationTime = Date.now() + cleverapps.Random.random(5, 15) * 1000;

            this.fogAnimation.setAnimationAndIdleAfter("highlight", "idle");
            this.fogAnimation.setCompleteListenerOnce(function () {
                this.setHighlightAnimation();
            }.bind(this));
        }.bind(this))
    ));
};

FogTileView.prototype.getHighlightAnimationDelay = function () {
    var start = FogTileView.MIN_HIGHLIGHT_DELAY;
    var end = FogTileView.MAX_HIGHLIGHT_DELAY - start;

    return start + Math.random() * end;
};

FogTileView.prototype.addGroundBg = function () {
    var styles = this.getStyles();

    var groundBg = this.groundBg = new cc.Sprite(this.fogTile.getFogBundle().frames.ground);
    groundBg.setPositionRound(styles.groundBg);
    this.tileView.addChild(groundBg, -1);
};

FogTileView.prototype.getFogAnimation = function () {
    var fogType = this.fogTile.getFogType();
    var jsons = [1, 2, 3, 4].map(function (num) {
        return this.fogTile.getFogBundle().jsons["fog" + num + "_json_" + fogType];
    }.bind(this)).filter(Boolean);

    return jsons.length === 1 ? jsons[0] : jsons[cleverapps.Random.random(0, jsons.length - 1, this.fogTile.x * 1000 + this.fogTile.y)];
};

FogTileView.prototype.onOpen = function (callback, silent) {
    callback = callback || function () {};

    this.fogTile.clear();
    this.stopAllActions();

    if (silent) {
        callback();
        this.removeFromParent();
        return;
    }

    if (this.fogAnimation) {
        var scene = cleverapps.scenes.getRunningScene();
        this.tileView.replaceParentSamePlace(scene.getAnimationsLayer());

        var sound = this.fogTile.getFogBundle().urls["open_effect_" + this.fogTile.getFogType()];
        cleverapps.audio.playSound(sound);

        if (this.groundBg) {
            this.groundBg.runAction(new cc.Sequence(
                new cc.FadeOut(0.5),
                new cc.RemoveSelf()
            ));
        }

        this.fogAnimation.setAnimation(0, "close", false);
        this.fogAnimation.setCompleteListenerOnce(function () {
            this.removeFromParent();
            this.tileView.removeFromParent();
        }.bind(this));

        callback();
    }
};

FogTileView.MIN_HIGHLIGHT_DELAY = 5;
FogTileView.MAX_HIGHLIGHT_DELAY = 30;

FogTileView.prototype.addFakeUnitNode = function () {

};

cleverapps.styles.FogTileView["garden"] = cleverapps.styles.FogTileView["hearts"] = {
    anchorX: 0.5,
    anchorY: 0.5,

    groundStateOnly: true,
    noBorder: true,

    groundBg: {
        x: { align: "center" },
        y: { align: "center" }
    },

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            zOrder: 0
        }
    }
};