/**
 * Created by anatoly on 06.02.2025
 */

cleverapps.overrideStyles(cleverapps.styles.MineableView, {
    energy: {
        x: { align: "center", dx: 42 },
        y: { align: "top", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitView, {
    enableSpawnPath: false,

    minimalSize: {
        width: 115,
        height: 115
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorY: 0.77
        }
    }
});

if (cleverapps.styles.OrangeryView) {
    cleverapps.overrideStyles(cleverapps.styles.OrangeryView, {
        minimized: {
            x: 150
        }
    });
}