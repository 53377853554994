/**
 * Created by Denis Kuzin on 02 april 2025
 */

var Merge2StartFixTutorial = function () {
    return new TutorialScenario("merge2_start_fix_tutorial", {
        listSteps: Merge2StartFixTutorial.listSteps,

        isAvailable: function () {
            var location = cleverapps.meta.getSelectedLocation();
            return location.getProgress() === 0 && location.stars >= location.getCurrentStagePrice() * 2;
        }
    });
};

Merge2StartFixTutorial.listSteps = function () {
    return [
        Merge2StartFixTutorial.createMetaSwitchStep(),
        Merge2StartFixTutorial.createFirstRepairStep(),
        Merge2StartFixTutorial.createSecondRepairStep(),
        Merge2StartFixTutorial.createGameSwitchStep()
    ];
};

Merge2StartFixTutorial.createMetaSwitchStep = function () {
    var options = {
        onStart: function () {
            cleverapps.focusManager.showControlsWhileFocused("metaSwitchButton");

            var metaSwitchButton = cleverapps.scenes.getRunningScene().metaSwitchButton;

            this.force = cleverapps.forces.create(metaSwitchButton, {
                text: "Merge2.StartFixTutorial1"
            });

            cleverapps.tutorial.on("metaSwitchButton", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
            cleverapps.tutorial.startNextStep();
        }
    };

    return new TutorialStep(options);
};

Merge2StartFixTutorial.createFirstRepairStep = function () {
    var options = {
        onStart: function () {
            cleverapps.focusManager.showControlsWhileFocused("MenuBarGardenProgressItem");
            cleverapps.focusManager.showControlsWhileFocused("MenuBarOrderStarsItem");

            var repairView = cleverapps.scenes.getRunningScene().roomView.repairView;

            this.force = cleverapps.forces.create(repairView, {
                text: "Merge2.StartFixTutorial2"
            }, {
                styles: { shadow: false }
            });

            cleverapps.tutorial.on("roomRepair", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
            cleverapps.tutorial.startNextStep();
        }
    };

    return new TutorialStep(options);
};

Merge2StartFixTutorial.createSecondRepairStep = function () {
    var options = {
        onStart: function () {
            var repairView = cleverapps.scenes.getRunningScene().roomView.repairView;

            this.force = cleverapps.forces.create(repairView, {
                text: "Merge2.StartFixTutorial3"
            }, {
                styles: { shadow: false }
            });

            cleverapps.tutorial.on("roomRepair", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
            cleverapps.tutorial.startNextStep();
        }
    };

    return new TutorialStep(options);
};

Merge2StartFixTutorial.createGameSwitchStep = function () {
    var options = {
        onStart: function () {
            var gameSwitchButton = cleverapps.scenes.getRunningScene().gameSwitchComponent.gameSwitchButton;

            this.force = cleverapps.forces.create(gameSwitchButton, {
                text: "Merge2.StartFixTutorial4"
            });

            cleverapps.tutorial.on("gameSwitchButton", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
            cleverapps.tutorial.startNextStep();
        }
    };

    return new TutorialStep(options);
};
