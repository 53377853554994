/**
 * Created by Andrey Popov on 4/2/25.
 */

MineableView.prototype.createProgress = function () {
    this.energyView = new cleverapps.Spine(bundles.merge.jsons.mineable_energy_json);
    this.energyView.setAnchorPoint(0.5, 0.5);
    this.energyView.setPositionRound(cleverapps.styles.MineableView.energy);

    this.unitView.addChild(this.energyView);

    this.updateAfterEnergyChanged(true);
};

MineableView.prototype.updateAfterEnergyChanged = function (init) {
    if (this.mine.active) {
        if (this.unitView.sprite instanceof cleverapps.Spine && this.unitView.sprite.hasAnimation("end_inactive")) {
            this.unitView.sprite.setAnimationAndIdleAfter("end_inactive", "idle");
        }

        if (init && this.energyView.hasAnimation("showup")) {
            this.energyView.setAnimationAndIdleAfter("showup", "active");
        } else {
            this.energyView.setAnimation(0, "active", true);
        }

        if (!this.energyHoverHandler && this.energyView.hasAnimation("hover")) {
            this.energyHoverHandler = cleverapps.UI.applyHover(this.unitView, {
                onMouseOver: function () {
                    this.energyView.setAnimation(0, "hover", true);
                }.bind(this),
                onMouseOut: function () {
                    this.energyView.setAnimation(0, this.mine.active ? "active" : "inactive", true);
                }.bind(this)
            });
        }
    } else {
        if (this.unitView.sprite instanceof cleverapps.Spine && this.unitView.sprite.hasAnimation("start_inactive")) {
            this.unitView.sprite.setAnimationAndIdleAfter("start_inactive", "inactive");
        }

        this.energyView.setAnimation(0, "inactive", true);

        if (this.energyHoverHandler) {
            this.energyHoverHandler.remove();
            this.energyHoverHandler = undefined;
        }
    }
};

MineableView.prototype.handlePrizes = function () {
    if (this.unitView.sprite instanceof cleverapps.Spine) {
        this.unitView.sprite.setAnimation(0, "spawn", false);
        this.unitView.sprite.setCompleteListenerOnce(function () {
            this.unitView.sprite.setAnimation(0, !this.mine.active && this.unitView.sprite.hasAnimation("inactive") ? "inactive" : "idle", true);
        }.bind(this));
        this.unitView.sprite.setSafeToRemove();
    }

    this.energyView.setAnimationAndIdleAfter("click", this.mine.active ? "hover" : "inactive");
};

MineableView.prototype.onSelect = function () {
    var duration = 0.1;
    var baseScale = this.unitView.sprite.getScale();

    var iteration = function (dScale) {
        return new cc.Sequence(
            new cc.ScaleTo(duration, baseScale + dScale).easing(cc.easeOut(1)),
            new cc.ScaleTo(duration, baseScale).easing(cc.easeOut(1)),
            new cc.ScaleTo(duration, baseScale - dScale).easing(cc.easeOut(1)),
            new cc.ScaleTo(duration, baseScale).easing(cc.easeOut(1))
        );
    };

    this.unitView.sprite.runAction(new cc.Sequence(
        iteration(0.06),
        iteration(0.04),
        iteration(0.015)
    ));
};