/**
 * Created by anatoly on 27.12.2024.
 */

var ValuableHintView = function () {
    this.hintFinger = undefined;

    cleverapps.availableMove.on("hide", this.hide.bind(this));
    cleverapps.availableMove.on(ValuableHintLogic.type, this.show.bind(this));
};

ValuableHintView.prototype.show = function (options) {
    this.hide();

    var target = options.target;
    this.hintFinger = FingerView.hintTap(target);
};

ValuableHintView.prototype.hide = function () {
    FingerView.remove(this.hintFinger);
    this.hintFinger = undefined;
};
