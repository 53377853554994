/**
 * Created by Denis Kuzin on 19 february 2025
 */

var OrderIngredientView = cc.Node.extend({
    ctor: function (ingredient, styles) {
        this._super();
        this.ingredient = ingredient;
        this.styles = styles;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.styles.size);
        this.setCascadeOpacityEnabledRecursively(true);

        this.createIngredient();
        this.createCheckMark();

        this.refresh();

        cleverapps.UI.onClick(this, function () {
            cleverapps.focusManager.display({
                focus: "HelpButtonClicked",
                action: function (f) {
                    new UnitsLibraryWindow(this.getIngredientUnit());
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this)
            });
        }.bind(this), { interactiveScale: false });
    },

    getIngredientUnit: function () {
        return this.ingredient.unit;
    },

    createIngredient: function () {
        this.icon = UnitView.getUnitImage(this.getIngredientUnit(), { preferStatic: true, useLibraryAnchors: true });

        this.addChild(this.icon);
        this.icon.setPosition(this.width / 2, this.height / 2);
        cleverapps.UI.fitToBox(this.icon, { width: this.width, height: this.height });
    },

    createCheckMark: function () {
        this.checkMark = new cc.Sprite(bundles.questsgroupicon.frames.check_mark);

        this.addChild(this.checkMark);
        this.checkMark.setPositionRound(this.styles.checkMark);

        cleverapps.UI.fitToBox(this.checkMark, { width: this.styles.checkMark.width, height: this.styles.checkMark.height });
    },

    refresh: function (isTransparent) {
        var isReady = Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0;
        this.checkMark.setVisible(isReady);
        this.setOpacity(isTransparent ? 100 : 255);
    },

    playIconBounceAnimation: function () {
        var scale = this.icon.getScale();
        this.icon.runAction(new cc.Sequence(
            new cc.ScaleTo(0.1, scale * 0.8).easing(cc.easeOut(1)),
            new cc.ScaleTo(0.15, scale * 1.05).easing(cc.easeOut(1)),
            new cc.ScaleTo(0.2, scale).easing(cc.easeIn(1))
        ));
    }
});
