/**
 * Created by Vladislav on 27.03.2025.
 */

var GardenProgressMenubarItemView = MenuBarItemView.extend({
    ctor: function (item) {
        this._super(item);

        cleverapps.meta.getSelectedLocation().on("nextStage", this.onChangeStage.bind(this));
        if (cleverapps.config.debugMode) {
            cleverapps.meta.getSelectedLocation().on("reset", this.onChangeStage.bind(this));
        }
    },

    reset: function () {
        var styles = cleverapps.styles.GardenProgressMenubarItemView;

        this.progressBar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_green_large,
            background: bundles.progress_bar.frames.bg_green_large,
            barText: {
                text: "%percentage%%",
                font: cleverapps.styles.FONTS.WHITE_TEXT
            }
        });
        this.progressBar.setLength(styles.bar.width);
        this.progressBar.setPercentage(cleverapps.meta.getSelectedLocation().getProgress());

        this.setContentSize(this.progressBar.getContentSize());

        this.addChild(this.progressBar);
        this.progressBar.setPositionRound(this.width / 2, this.height / 2);

        this.updateClickable();
    },

    onChangeStage: function () {
        this.progressBar.setPercentage(cleverapps.meta.getSelectedLocation().getProgress(), { animated: true });
    }
});

cleverapps.styles.GardenProgressMenubarItemView = {
    bar: {
        width: 320
    }
};