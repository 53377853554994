/**
 * Created by Denis Kuzin on 07 february 2025
 */

Customer.prototype.enjoy = function () {
    if (this.getState() === Customer.STATE_COOKING) {
        var orderStar = this.recipe.getOrderStar();
        if (orderStar) {
            var upgradable = this.getUpgradableTarget("castle");
            if (upgradable) {
                for (var i = 0; i < (orderStar.amount || 1); i++) {
                    upgradable.incProgress();
                }

                this.recipe.order = this.recipe.order.filter(function (item) {
                    return item.ingredient !== "orderStar";
                });
            }
        }

        this._setState(Customer.STATE_READY, this.recipe);

        Merge.currentMerge.pushes.updateOrders();

        this.checkAndHarvestPrize();
    }
};

Customer.prototype.handleClick = function () {
    return true;
};

Customer.prototype.getUpgradableTarget = function (type) {
    if (!this.recipe.getOrderStar()) {
        return;
    }

    var castle = Map2d.currentMap.listAvailableUnits({ type: type }).filter(function (castle) {
        return castle.findComponent(Upgradable);
    }).sort(function (a, b) {
        return Map2d.currentMap.getCenterDistance(a) - Map2d.currentMap.getCenterDistance(b);
    })[0];

    if (castle) {
        Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, castle.x, castle.y);
        return castle.findComponent(Upgradable);
    }
};

Customer.prototype.setRecipePrizes = function () {
    if (Map2d.currentMap.isFlat) {
        // rewards collected using rewardsList.receiveRewardsAnimation
        return;
    }

    this.unit.setPrizes(this.recipe.listPrize(this.unit.prizesCount));
    this.unit.takePrizes();
};

Customer.prototype.exchange = function (callback) {
    cleverapps.focusManager.display({
        focus: "OrderExchange",
        stack: cleverapps.tutorial.isActive(),
        keepControls: true,
        action: function (f) {
            cleverapps.audio.playSound(bundles.game.urls.click_field_effect);

            this.cook(function () {
                callback && callback();
                Merge.currentMerge.counter.setTimeout(f, 1500);
            });
        }.bind(this)
    });
};

Customer.prototype._setState = cleverapps.extendFunc(Customer.prototype._setState, function () {
    this._super.apply(this, arguments);

    var table = Merge.currentMerge.scrollableIconTable;
    if (!table) {
        return;
    }

    if (arguments[0] === Customer.STATE_RECIPE) {
        table.addIcon(this);
    }

    if (arguments[0] === Customer.STATE_READY) {
        var iconToRemove = table.findIcon(this);
        if (iconToRemove) {
            table.removeIcon(iconToRemove);
        }
    }
});

if (cleverapps.config.name === "garden") {
    Customer.COOLDOWN_DELAY.CUSTOMER = cleverapps.parseInterval("0 seconds");
} else {
    Customer.COOLDOWN_DELAY.CUSTOMER = cleverapps.parseInterval("3 seconds");
}