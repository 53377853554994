/**
 * Created by razial on 19.03.2025.
 */

var GardenLocationAdapter = function (slot) {
    this.slot = slot;
};

GardenLocationAdapter.prototype.isNewGame = function () {
    return false;
};

GardenLocationAdapter.prototype.load = function (key) {
    return cleverapps.dataLoader.load(GardenLocationAdapter.GetStoreKey(this.slot, key));
};

GardenLocationAdapter.prototype.save = function (key, info) {
    cleverapps.dataLoader.save(GardenLocationAdapter.GetStoreKey(this.slot, key), info);

    cleverapps.synchronizer.addUpdateTask("locations" + this.slot);
};

GardenLocationAdapter.prototype.remove = function (key) {
    cleverapps.dataLoader.remove(GardenLocationAdapter.GetStoreKey(this.slot, key));

    cleverapps.synchronizer.addUpdateTask("locations" + this.slot);
};

GardenLocationAdapter.prototype.removeUnitsInFog = function () {

};

GardenLocationAdapter.prototype.restoreKickOuts = function () {

};

GardenLocationAdapter.prototype.checkReset = function () {

};

GardenLocationAdapter.prototype.checkReset2 = function () {

};

GardenLocationAdapter.GetStoreKey = function (slot, key) {
    return DataLoader.TYPES.LOCATION + slot + "_" + key;
};

GardenLocationAdapter.Import = function (slot, serverData) {
    GardenLocationAdapter.SYNCED_KEYS.forEach(function (key) {
        var value = serverData[key];
        if (value !== undefined) {
            cleverapps.dataLoader.save(GardenLocationAdapter.GetStoreKey(slot, key), serverData[key]);
        } else {
            cleverapps.dataLoader.remove(GardenLocationAdapter.GetStoreKey(slot, key));
        }
    });

    GardenLocationAdapter.ExpandUnits(slot, serverData);
};

GardenLocationAdapter.Export = function (slot) {
    var serverData = {};

    GardenLocationAdapter.SYNCED_KEYS.forEach(function (key) {
        var value = cleverapps.dataLoader.load(GardenLocationAdapter.GetStoreKey(slot, key));
        if (value !== undefined) {
            serverData[key] = value;
        }
    });

    GardenLocationAdapter.CompactUnits(slot, serverData);

    return serverData;
};

GardenLocationAdapter.CompactUnits = function (slot, serverData) {
    var size = cleverapps.dataLoader.load(GardenLocationAdapter.GetStoreKey(slot, "size")) || {};
    if (!size.width || !size.height) {
        return;
    }

    var units = "";
    var details = "";

    var propertiesCount = 0;
    var propertiesCache = {};

    for (var y = 0; y < size.height; ++y) {
        for (var x = 0; x < size.width; ++x) {
            var key = Map2d.GetCellKey(x, y);
            var info = cleverapps.dataLoader.load(GardenLocationAdapter.GetStoreKey(slot, key));
            if (!info) {
                units += "  ";
                continue;
            }

            units += info.code + Base80.compactNumber(info.stage);

            if (info.code.length !== 1) {
                throw "wrong unit code format - can be only one character " + info.code + " " + info.stage;
            }

            var properties = "";

            for (key in info) {
                if (key === "code" || key === "stage") {
                    continue;
                }

                var value = info[key];

                if (!Number.isInteger(value)) {
                    throw "only integers are allowed in unit properties " + info.code + " " + info.stage + " " + value;
                }

                var property = key + ":" + Base80.compactNumber(info[key]);

                var index = propertiesCache[property];
                if (index === undefined) {
                    index = propertiesCache[property] = propertiesCount;
                    propertiesCount += 1;
                }

                properties += Base80.compactExtendedNumber(index);
            }

            if (properties.length) {
                if (details.length) {
                    details += ";";
                }
                details += Base80.compactExtendedNumber(x) + Base80.compactExtendedNumber(y) + properties;
            }
        }
    }

    propertiesCache = Object.keys(propertiesCache).sort(function (a, b) {
        return propertiesCache[a] - propertiesCache[b];
    }).join(",");

    serverData.units = units;
    serverData.details = Base80.compactExtendedNumber(size.width) + Base80.compactExtendedNumber(size.height) + ";" + propertiesCache + ";" + details;
};

GardenLocationAdapter.ExpandUnits = function (slot, serverData) {
    var details = (serverData.details || "").split(";");

    var size = Base80.expandExtendedSequence(details.shift() || "");
    size = { width: size[0] || 0, height: size[1] || 0 };

    var propertiesCache = (details.shift() || "").split(",");

    cleverapps.dataLoader.save(GardenLocationAdapter.GetStoreKey(slot, "size"), size);

    if (size.width === 0 || size.height === 0) {
        return;
    }

    var units = serverData.units || "";

    details = details.reduce(function (details, info) {
        info = Base80.expandExtendedSequence(info);
        if (info.length > 2) {
            details[info[0] + "_" + info[1]] = info.slice(2).reduce(function (info, value) {
                value = (propertiesCache[value] || "").split(":");
                if (value.length === 2) {
                    info[value[0]] = Base80.expandNumber(value[1]);
                }

                return info;
            }, {});
        }

        return details;
    }, {});

    for (var y = 0; y < size.height; ++y) {
        for (var x = 0; x < size.width; ++x) {
            var index = (y * size.width + x) * 2;

            var code = units[index];
            var stage = Base80.expandNumber(units[index + 1]);

            if (code !== " " && !isNaN(stage)) {
                var info = details[x + "_" + y] || {};

                info.code = code;
                info.stage = stage;

                cleverapps.dataLoader.save(GardenLocationAdapter.GetStoreKey(slot, Map2d.GetCellKey(x, y)), info);
                continue;
            }

            cleverapps.dataLoader.remove(GardenLocationAdapter.GetStoreKey(slot, Map2d.GetCellKey(x, y)));
        }
    }
};

GardenLocationAdapter.SYNCED_KEYS = ["fogs", "stars"];

CustomSyncers.registerBySlots("locations", GardenLocationAdapter.Export, GardenLocationAdapter.Import);
