/**
 * Created by anatoly on 27.12.2024
 */

var MergeHintLogics = {
    type: "merge",

    findMove: function () {
        var map = Map2d.currentMap;

        var units = map.listAvailableUnits();

        var used = {};
        var cluster = [];

        var minWeight = Infinity;
        units.forEach(function (unit) {
            if (used[Unit.GetPositionKey(unit)] || unit.stage > minWeight || unit.isGrounded() || unit.customerMark) {
                return;
            }

            used[Unit.GetPositionKey(unit)] = true;

            var possibleMergeGrounded;
            var possibleMergePoint;

            map.iterateBfs(unit.x, unit.y, function (p) {
                if (possibleMergeGrounded || (p.x === unit.x && p.y === unit.y)) {
                    return;
                }

                if (map.compareMergeable(unit, p.x, p.y)) {
                    var otherUnitGrounded = map.getUnit(p.x, p.y).isGrounded();
                    if (!possibleMergePoint || otherUnitGrounded) {
                        possibleMergePoint = p;
                        possibleMergeGrounded = otherUnitGrounded;
                    }
                }
            });

            if (!possibleMergePoint) {
                return;
            }

            if (unit.stage < minWeight) {
                cluster = [];
                minWeight = unit.stage;
            }

            cluster.push({
                withGrounded: possibleMergeGrounded,
                cells: [{ x: unit.x, y: unit.y }, possibleMergePoint]
            });
        });

        if (cluster.length === 0) {
            return;
        }

        var groundedCluster = cluster.filter(function (solution) {
            return solution.withGrounded;
        });

        var solution = cleverapps.Random.choose(groundedCluster.length > 0 ? groundedCluster : cluster);

        return {
            type: MergeHintLogics.type,
            options: {
                cells: solution.cells
            }
        };
    }
};

AvailableMove.REGISTERED_ALGORITHMS.push(MergeHintLogics);