/**
 * Created by Vladislav on 31.03.2025.
 */

var GameSwitchButton = cleverapps.UI.Button.extend({
    ctor: function (switchAction) {
        var styles = cleverapps.styles.GameSwitchButton;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        var icon = new cc.Sprite(bundles.buttons.frames.game_button_icon);
        content.addChild(icon);
        content.setContentSize(icon.getContentSize());
        icon.setPositionRound(styles.icon);

        this._super({
            width: styles.width,
            height: styles.height,
            content: content,
            type: cleverapps.styles.UI.Button.Images.green_triangular_button,
            onClicked: function () {
                if (cleverapps.tutorial.isActive()) {
                    switchAction(function () {
                        cleverapps.tutorial.trigger("gameSwitchButton");
                    });

                    return;
                }

                cleverapps.focusManager.display({
                    focus: "GameSwitchButton",
                    keepControls: true,
                    action: switchAction
                });
            }
        });
    }
});

cleverapps.styles.GameSwitchButton = {
    width: 173,
    height: 198,
    icon: {
        x: { align: "center", dx: -10 },
        y: { align: "center" }
    }
};