/**
 * Created by Denis Kuzin on 15 january 2025
 */
Unit.prototype.onMarkError = function () {};

Unit.prototype.onMarkDragging = function () {};

Unit.NEXT_CELL_CHECK_THRESHOLD = 0;

Unit.INTACT_ENABLED = false;
