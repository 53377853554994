/**
 * Created by anatoly on 13.01.2025
 */

Mineable.prototype.init = function () {
    cleverapps.lives.onChangeAmountListeners[Unit.GetKey(this.unit)] = this.onEnergyChanged.bind(this);
    this.onEnergyChanged();
};

Mineable.prototype.handleNoSpaceOnStart = function () {
    if (!Map2d.currentMap.countEmptySlots()) {
        cleverapps.centerHint.createTextHint("Spawn.nospace");
        cleverapps.audio.playSound(bundles.game.urls.board_full_effect);
        return true;
    }

    return false;
};

Mineable.prototype.handleClick = function () {
    var selection = Map2d.currentMap.unitInfo && Map2d.currentMap.unitInfo.lastSelection;

    if (cleverapps.lives.isEmpty()) {
        cleverapps.audio.playSound(bundles.game.urls.generator_off_effect);
    }

    if (selection && this.unit.x === selection.x && this.unit.y === selection.y) {
        this.startMine();
        return true;
    }

    if (Map2d.currentMap.isFlat) {
        this.onSelect();
    }

    return false;
};

Mineable.prototype.onSelect = function () {
    if (this.view) {
        this.view.onSelect();
    }
};

Mineable.prototype.listPrize = function () {
    var info = Mines[this.unit.getType()](this.mined, this.unit);
    return Prizes.Generate(cleverapps.toArray(info));
};

Mineable.prototype.startMine = function () {
    if (this.handleNoSpaceOnStart()) {
        return false;
    }

    var task = this.getTask();
    if (!Merge.currentMerge.confirmEnoughEnergy(task.energy)) {
        return false;
    }

    Merge.currentMerge.useEnergy(task.energy, this.unit);

    this.completeMining();

    return true;
};

Mineable.prototype.getActionInfo = function () {
    this.startMine();
};

Mineable.prototype.nextPosition = function () {};

Mineable.prototype.completeMining = function () {
    cleverapps.tutorial.trigger("mine");
    this.unit.setPrizes(this.listPrize(), this.getTask().exp, true);
    this.unit.takePrizes({
        sound: bundles.merge.urls[this.unit.code + "_mining_effect"]
    });
};

Mineable.prototype.onEnergyChanged = function () {
    var active = !cleverapps.lives.isEmpty();
    if (active !== this.active) {
        this.active = active;
        this.view && this.view.updateAfterEnergyChanged();
    }
};

Mineable.prototype.destructor = cleverapps.extendFunc(Mineable.prototype.destructor, function () {
    delete cleverapps.lives.onChangeAmountListeners[Unit.GetKey(this.unit)];

    return this._super.apply(this, arguments);
});