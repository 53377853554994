/**
 * Created by Denis Kuzin on 23 december 2024
 */

var Map2dFlatView = cc.Node.extend({
    ctor: function (map2d) {
        this._super();

        this.map2d = map2d;
        this.map2d.isFlat = true;

        this.rotated = cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL;

        this.setContentSize(this.getSize());

        this.setAnchorPoint(0.5, 0.5);

        var animations = this.animations = this.movingNode = new cc.Node();
        animations.setContentSize(this.getContentSize());
        animations.setPositionRound(this.width / 2, this.height / 2);
        animations.setAnchorPoint(0.5, 0.5);
        animations.debugId = "Animations";
        this.addChild(animations, Map2dFlatView.ZORDERS.ANIMATIONS);

        if (cleverapps.config.debugMode) {
            animations._debugExclude = true;
        }

        map2d.onAdd = this.createListener(function (layer, x, y, object) {
            if (layer === Map2d.LAYER_FOG && object instanceof FogTile) {
                this.addToLayer(layer, x, y);
            }
        }.bind(this));
        map2d.onAddUnit = this.createListener(this.addToLayer.bind(this, Map2d.LAYER_UNITS));
        map2d.onDiscoverMapView = function () {
            return this;
        }.bind(this);

        this.updateBorders();

        for (var y = 0; y < this.map2d.getHeight(); y++) {
            for (var x = 0; x < this.map2d.getWidth(); x++) {
                var layers = [
                    Map2d.LAYER_GROUND,
                    Map2d.LAYER_FOG,
                    Map2d.LAYER_UNITS
                ];

                for (var i = 0; i < layers.length; i++) {
                    var layer = layers[i];
                    this.map2d.setPointer(x, y);
                    this.addToLayer(layer, x, y);
                }
                this.map2d.decorators.addDecoratorsViews(x, y);
            }
        }

        this.selection = new cleverapps.Spine(bundles.highlight_animations.jsons.hint_json);
        this.animations.addChild(this.selection);

        this.map2d.unitInfo.on("onSelectUnit", this.showSelection.bind(this));
        this.map2d.unitInfo.on("onHideSelect", this.hideSelection.bind(this));

        this.addControls();
        cleverapps.aims.registerTarget("units", this, {
            noTargetDelta: true,
            flyingUnderShadow: true,
            flyingAnimation: Reward.SPAWN_UNITS_ANIMATION
        });
    },

    isRotated: function () {
        return this.rotated;
    },

    rotate: function () {
        this.rotated = !this.rotated;

        var size = this.getSize();

        this.setContentSize(size);

        this.animations.setContentSize(size);
        this.animations.setPositionRound(this.width / 2, this.height / 2);

        this.controlsNode.setContentSize(size);
        this.controlsNode.setPositionRound(this.width / 2, this.height / 2);

        this.updateBorders();
        this.updatePositions(this);
        this.updatePositions(this.animations);
    },

    getSize: function () {
        var size = {
            width: this.map2d.getWidth() * cleverapps.styles.Map2dFlatView.cell.width,
            height: this.map2d.getHeight() * cleverapps.styles.Map2dFlatView.cell.height
        };

        if (this.isRotated()) {
            return { width: size.height, height: size.width };
        }

        return size;
    },

    updatePositions: function (node) {
        node.children.forEach(function (child) {
            if (child.onMapRotated) {
                child.onMapRotated();
            }

            var cell = child.cell;
            if (!cell) {
                return;
            }

            this.alignViewInGrid(cell.x, cell.y, child);

            if (child.additionalViews) {
                for (var type in child.additionalViews) {
                    var view = child.additionalViews[type];
                    this.alignViewInGrid(cell.x, cell.y, view);
                }
            }
        }.bind(this));
    },

    updateBorders: function () {
        var area = [];
        this.map2d.map(this.map2d.layers[Map2d.LAYER_GROUND], function (cell, x, y) {
            if (cell) {
                area.push(cc.p(x, y));
            }
        });

        if (!this.map2d.decorators.haveDecorators()) {
            this.createBorderDecorators(area);
        }

        if (this.isRotated()) {
            area = area.map(function (p) {
                return this.convertToVertical(p);
            }.bind(this));
        }

        BorderRenderer.place(
            area,
            function (borders, cell) {
                borders.forEach(function (border, index) {
                    if (!Map2dDecoratorView.getFrame({ code: border.name })) {
                        return;
                    }

                    this.updateBorderDecorators(border.name, cell, index);
                }.bind(this));
            }.bind(this),
            { yAxis: -1 }
        );
    },

    createBorderDecorators: function (area) {
        BorderRenderer.place(
            area,
            function (borders, cell) {
                borders.forEach(function () {
                    var fence = new Map2dDecorator({
                        x: cell.x,
                        y: cell.y,
                        dynamic: true
                    });

                    Map2d.currentMap.decorators.addDecorator(cell.x, cell.y, fence);
                });
            },
            { yAxis: -1 }
        );
    },

    updateBorderDecorators: function (imageName, cell, index) {
        var styles = cleverapps.styles.Map2dFlatView;

        if (this.isRotated()) {
            cell = this.convertToHorizontal(cell);
        }

        var fences = Map2d.currentMap.decorators.getDecorators(cell.x, cell.y);
        if (!fences) {
            return;
        }

        var fence = fences[index];

        var cellSize = styles.cell;

        fence.dx = (imageName.includes("right") ? 1 : imageName.includes("left") ? -1 : 0) * (cellSize.width / 2 + styles.borderPadding);
        fence.dy = (imageName.includes("up") ? 1 : imageName.includes("down") ? -1 : 0) * (cellSize.height / 2 + styles.borderPadding);

        fence.code = imageName;
    },

    addControls: function () {
        var controlsNode = this.controlsNode = new Map2dControls(this);
        controlsNode.debugId = "Map2dFlatView";
        this.addChild(controlsNode, -1);
    },

    findTouchUnitOrDecorator: function (touch) {
        var cell = this.getCellByTouch(touch);

        if (cell) {
            var activeScenario = cleverapps.tutorial.getActive();
            var tutorialUnits = activeScenario && activeScenario.getCurrentStep().units;

            if (tutorialUnits && !tutorialUnits.some(function (unit) {
                return cell.x === unit.x && cell.y === unit.y;
            })) {
                return;
            }

            var unit = Map2d.currentMap.getUnitWithHead(cell.x, cell.y);

            if (unit && unit.checkTouchInside(touch)) {
                return unit;
            }
        }
    },

    findTouchFog: function () {

    },

    getCellByTouch: function (touch) {
        var pos = this.convertTouchToNodeSpace(touch);
        return this.getCellByCoordinates(pos);
    },

    getCellByCoordinates: function (p, rawValue) {
        var zero = this.alignInGrid(0, 0);

        var x = Math.abs((p.x - zero.x) / cleverapps.styles.Map2dFlatView.cell.width);
        var y = Math.abs((p.y - zero.y) / cleverapps.styles.Map2dFlatView.cell.height);

        p = rawValue ? cc.p(x, y) : cc.p(Math.round(x), Math.round(y));

        if (this.isRotated()) {
            p = cc.p(p.y, p.x);
        }

        return p;
    },

    addToLayer: function (layer, x, y) {
        var object = this.map2d.getValue(layer, x, y);
        if (object) {
            var view;

            switch (layer) {
                case Map2d.LAYER_UNITS:
                    if (this.map2d.getValue(Map2d.LAYER_GROUND, x, y)) {
                        view = new UnitView(object);
                        view.sprite._debugExclude = true;
                    }
                    break;
                case Map2d.LAYER_GROUND:
                    view = ViewReader.parse(object, this.map2d);
                    break;
                case Map2d.LAYER_FOG:
                    view = new FogTileView(object);
                    break;
            }

            if (!view) {
                return;
            }

            this.addTile(layer, x, y, view);

            if (view.additionalViews) {
                Object.values(view.additionalViews).forEach(function (additionalView) {
                    this.upAdditionalViewAboveClouds(view, additionalView);
                }.bind(this));
            }
        }
    },

    upAdditionalViewAboveClouds: function (unitView, additionalView) {
        if (unitView.parent) {
            if (additionalView.type === "unitMarkerUnderlay") {
                additionalView.replaceParentSamePlace(this);
                additionalView.setLocalZOrder(-1);
                return;
            }

            additionalView.replaceParentSamePlace(this.animations);
            additionalView.setLocalZOrder(1);
        }
    },

    onCellClick: function () {
        this.map2d.hideSelect();
    },

    containsView: function (unitView) {
        return unitView.getParent() === this;
    },

    showSelection: function (unit) {
        this.alignViewInGrid(unit.x, unit.y, this.selection);
        this.selection.setVisible(true);

        this.selection.setAnimationAndIdleAfter("show_up", "animation");
    },

    hideSelection: function () {
        this.selection.setVisible(false);
    }
});

Map2dFlatView.prototype.toScreen = function (x, y) {
    var position = cc.p(x * cleverapps.styles.Map2dFlatView.cell.width, y * cleverapps.styles.Map2dFlatView.cell.height);
    if (this.isRotated()) {
        return this.convertToVertical(position);
    }

    return position;
};

Map2dFlatView.prototype.alignInGrid = function (x, y) {
    var position = cc.p(x, y);
    if (this.isRotated()) {
        position = this.convertToVertical(position);
    }

    return cc.p(
        position.x * cleverapps.styles.Map2dFlatView.cell.width + cleverapps.styles.Map2dFlatView.cell.width / 2,
        position.y * cleverapps.styles.Map2dFlatView.cell.height + cleverapps.styles.Map2dFlatView.cell.height / 2
    );
};

Map2dFlatView.prototype.alignViewInGrid = function (x, y, view) {
    var cellPosition = this.alignInGrid(x, y);

    if (view instanceof Map2dDecoratorView && view.alignment) {
        var position = view.calculatePositionRound(view.alignment.x, view.alignment.y, { parentSize: { width: 0, height: 0 } });

        cellPosition = cc.pAdd(cellPosition, position);
    }

    view.cell = cc.p(x, y);
    view.setPositionRound(cellPosition.x, cellPosition.y);
};

Map2dFlatView.prototype.alignPositionInGrid = function (x, y, position) {
    var cellPosition = this.alignInGrid(x, y);
    return cc.p(position.x + cellPosition.x, position.y + cellPosition.y);
};

Map2dFlatView.prototype.getSourceCenterPosition = function (source) {
    return this.alignInGrid(source.x, source.y);
};

Map2dFlatView.prototype.addTile = function (layer, x, y, view) {
    if (view.parent) {
        view.replaceParentSamePlace(this);
    } else {
        this.addChild(view);
    }

    view.setLocalZOrder(Map2dFlatView.ZORDERS[layer] || 0);

    this.alignViewInGrid(x, y, view);
};

Map2dFlatView.prototype.getUnitCenterPos = function (x, y) {
    return this.alignInGrid(x, y);
};

Map2dFlatView.prototype.convertToVertical = function (p) {
    var x = p.y;
    var y = this.map2d.getWidth() - 1 - p.x;

    return cc.p(x, y);
};

Map2dFlatView.prototype.convertToHorizontal = function (p) {
    var y = p.x;
    var x = this.map2d.getWidth() - 1 - p.y;

    return cc.p(x, y);
};

Map2dFlatView.ZORDERS = {};

Map2dFlatView.ZORDERS[Map2d.LAYER_GROUND] = -1;
Map2dFlatView.ZORDERS[Map2d.LAYER_BORDERS] = 0;
Map2dFlatView.ZORDERS[Map2d.LAYER_UNITS] = 1;
Map2dFlatView.ZORDERS[Map2d.LAYER_FOG] = 2;

Map2dFlatView.ZORDERS.ANIMATIONS = 10;

cleverapps.styles.Map2dFlatView = {
    cell: {
        width: 148,
        height: 148
    },

    borderPadding: 8
};
