/**
 * Created by Aleksandr on 05.06.2024
 */

var TravelMapWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        options = options || {};

        this.animateNext = options.animateNext;

        var content = this.createContent();

        this._super({
            content: content,
            title: "TravelMapWindow.title",
            name: "travelmapwindow",
            closeButton: !this.animateNext,
            notCloseByTouchInShadow: this.animateNext,
            foreground: bundles.travel_map.frames.travel_foreground_png,
            styles: cleverapps.styles.TravelMapWindow
        });
    },

    onShow: function () {
        if (this.animateNext) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.CallFunc(this.runNextStageAnimation.bind(this)),
                new cc.DelayTime(7),
                new cc.CallFunc(this.close.bind(this))
            ));
        }
    },

    runNextStageAnimation: function () {
        var locationId = cleverapps.meta.selectedLocationId();

        this.locations[locationId].runPassedAnimation();
        this.locations[locationId + 1].runOpenAnimation();
    },

    createContent: function () {
        var styles = cleverapps.styles.TravelMapWindow;

        this.locations = {};

        var pages = [];

        this.listPages().forEach(function (locations, pageNo) {
            pages.push(this.createPage(pageNo, locations));
        }.bind(this));

        var contentLayer = new cleverapps.Layout(pages, {
            direction: this.isHorizontal() ? cleverapps.UI.HORIZONTAL : cleverapps.UI.VERTICAL
        });

        var scrollView = this.scrollView = new cleverapps.UI.ScrollView(contentLayer, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: this.isHorizontal() ? cleverapps.UI.ScrollView.DIR_HORIZONTAL : cleverapps.UI.ScrollView.Vertical,
            scrollBarEnabled: true,
            mouseScroll: true
        });

        scrollView.setContentSize(pages[0].width, pages[0].height);
        scrollView.setAnchorPoint(0.5, 0.5);
        scrollView.setPositionRound(styles.scrollView.position);
        scrollView.scrollTo(this.locations[cleverapps.meta.selectedLocationId()]);

        return scrollView;
    },

    listPages: function () {
        var pages = [];

        for (var locationId = 0; cleverapps.meta.isLocationAvailable(locationId); ++locationId) {
            if (locationId % TravelMapWindow.LEVELS_COUNT_IN_PAGE === 0) {
                if (pages.length === TravelMapWindow.PAGES_COUNT) {
                    break;
                }
                pages.push([]);
            }

            pages[pages.length - 1].push(locationId);
        }

        return pages;
    },

    createPage: function (pageNo, locations) {
        var backgroundBundleName = "tm_background_" + pageNo;
        if (!this.isHorizontal()) {
            backgroundBundleName += "_vertical";
        }
        var styles = cleverapps.styles.TravelMapWindow;
        var bg = new cleverapps.Spine(bundles.travel_map.jsons[backgroundBundleName]);
        bg.setAnimation(0, "animation");

        var activeLocations = cleverapps.meta.listActiveLocations();

        if (this.animateNext) {
            var currentLocationId = cleverapps.meta.nextLocationId(cleverapps.meta.selectedLocationId());

            activeLocations = activeLocations.filter(function (locationId) {
                return locationId !== currentLocationId;
            });
        }

        for (var i = 0; i < locations.length; ++i) {
            var locationId = locations[i];

            var state = TravelMapItemView.STATES.NEXT;

            var nextLocationId = cleverapps.meta.nextLocationId(locationId);

            if (activeLocations.includes(nextLocationId)) {
                state = TravelMapItemView.STATES.PASSED;
            } else if (activeLocations.includes(locationId)) {
                state = TravelMapItemView.STATES.CURRENT;
            }

            var isSelected = locationId === cleverapps.meta.selectedLocationId();

            var item = new TravelMapItemView(locationId, state, isSelected, this.animateNext);
            item.setPositionRound(styles.item[i]);
            bg.addChild(item);

            this.locations[locationId] = item;
        }

        return bg;
    },

    isHorizontal: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL;
    },

    listBundles: function () {
        return ["travel_map"];
    }
});

TravelMapWindow.LEVELS_COUNT_IN_PAGE = 4;
TravelMapWindow.PAGES_COUNT = 1;

cleverapps.styles.TravelMapWindow = {
    padding: {
        x: 26,
        top: 25,
        bottom: 35
    },

    scrollView: {
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    item: [
        [
            {
                x: { align: "center", dx: -196 },
                y: { align: "center", dy: 499 }
            },
            {
                x: { align: "center", dx: -196 },
                y: { align: "center", dy: 499 }
            },
            {
                x: { align: "center", dx: -606 },
                y: { align: "center", dy: -76 }
            }
        ],
        [
            {
                x: { align: "center", dx: 200 },
                y: { align: "center", dy: 200 }
            },
            {
                x: { align: "center", dx: 200 },
                y: { align: "center", dy: 200 }
            },
            {
                x: { align: "center", dx: -199 },
                y: { align: "center", dy: 187 }
            }
        ],
        [
            {
                x: { align: "center", dx: -190 },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "center", dx: -190 },
                y: { align: "center", dy: -100 }
            },
            {
                x: { align: "center", dx: 217 },
                y: { align: "center", dy: -73 }
            }

        ],
        [
            {
                x: { align: "center", dx: 190 },
                y: { align: "center", dy: -436 }
            },
            {
                x: { align: "center", dx: 190 },
                y: { align: "center", dy: -436 }
            },
            {
                x: { align: "center", dx: 596 },
                y: { align: "center", dy: 187 }
            }
        ]
    ],

    path: {
        x: { align: "left", dx: -75 },
        y: { align: "top", dy: -75 },
        offsetY: -90,
        dx: 50,
        dy: 25
    }
};