/**
 * Created by anatoly on 05.02.2025
 */

MergeComponentView.prototype.highlightMerge = function (affected) {
    var target = affected[0];

    this.highlightUnits([target], false, function (affectedUnit) {
        this.startAnimateMerge(affectedUnit.view);
    }.bind(this));
};

MergeComponentView.prototype.highlightMergeHint = function (affected) {
    var target = affected[1];
    this.highlightUnits(affected, false, function (affectedUnit) {
        var distance = this.calculateDistance(affectedUnit, target);
        this.startAnimateHint(affectedUnit.view, distance);

        target = affected[0];
    }.bind(this));
};

MergeComponentView.prototype.startAnimateHint = function (unitView, distance) {
    if (!unitView) {
        return;
    }

    if (unitView.mergeAnimateAction && !unitView.mergeAnimateAction.isDone()) {
        unitView.stopAction(unitView.mergeAnimateAction);
        delete unitView.mergeAnimateAction;
    }

    var baseScaleX = unitView.getScaleX();
    var baseScaleY = unitView.getScaleY();

    var delta = 0.2;

    var dx = distance.dx;
    var dy = distance.dy;

    unitView.mergeAnimateAction = unitView.runAction(new cc.RepeatForever(
        new cc.Sequence(
            new cc.Spawn(
                new cc.Sequence(
                    new cc.MoveBy(0.4, dx, dy).easing(cc.easeIn(1)),
                    new cc.MoveBy(0.25, -delta * dx, -delta * dy),
                    new cc.MoveBy(0.25, delta * dx, delta * dy),
                    new cc.MoveBy(0.4, -dx, -dy).easing(cc.easeOut(1))
                ),
                new cc.Sequence(
                    new cc.ScaleTo(0.4, baseScaleX * 1.1, baseScaleY * 1.1).easing(cc.easeIn(1)),
                    new cc.DelayTime(0.5),
                    new cc.ScaleTo(0.4, baseScaleX, baseScaleY).easing(cc.easeOut(1))
                )
            ),
            new cc.DelayTime(0.3)
        )
    )).setFinalize(function () {
        var mapView = Map2d.currentMap.getMapView();
        mapView.alignViewInGrid(unitView.unit.x, unitView.unit.y, unitView);
        unitView.setScale(baseScaleX, baseScaleY);
    });
};

MergeComponentView.prototype.startAnimateMerge = function (unitView) {
    if (!unitView) {
        return;
    }

    this.cleanAnimations(unitView);
    var rays = new cleverapps.Spine(bundles.merge.jsons.merge_rays_json);
    unitView.addChild(rays, -1);
    rays.setPositionRound({ align: "center" }, { align: "center" });
    rays.setAnimationAndIdleAfter("open", "idle");

    unitView.raysAnimation = rays;
};

cleverapps.overrideStyles(cleverapps.styles.MergeComponentView, {
    highlightLength: 28
});