/**
 * Created by anatoly on 04.03.2025
 */

var MergeHintView = function () {
    this.cell = undefined;

    cleverapps.availableMove.on("hide", this.hide.bind(this));
    cleverapps.availableMove.on(MergeHintLogics.type, this.show.bind(this));
};

MergeHintView.prototype.show = function (options) {
    this.hide();

    var cell = options.cells[0];
    var unit = Map2d.currentMap.getUnit(cell.x, cell.y);

    var mergeComponent = unit.findComponent(MergeComponent);
    if (mergeComponent) {
        mergeComponent.highlightMergeHint(options.cells);
    }

    this.cell = cell;
};

MergeHintView.prototype.hide = function () {
    if (!this.cell) {
        return;
    }

    var unit = Map2d.currentMap.getUnit(this.cell.x, this.cell.y);

    if (unit) {
        var mergeComponent = unit.findComponent(MergeComponent);
        mergeComponent.unhighlightMerge();
    }

    this.cell = undefined;
};